import {inject, Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import AC, {AgoraChat} from 'agora-chat';
import AgoraRTC, {ClientConfig, IAgoraRTCClient, ICameraVideoTrack, IMicrophoneAudioTrack,} from 'agora-rtc-sdk-ng';
import {HttpClient} from '@angular/common/http';
import {
    catchError,
    combineLatest,
    first,
    firstValueFrom,
    from,
    Observable,
    of,
    Subject,
    switchMap,
    throwError,
} from 'rxjs';
import {AgoraChatTokenDTO, AgoraConversation, AgoraRtcTokenDTO, VideoCallTracks,} from '../models/agora.models';
import {ModalService} from './modal.service';
import ErrorModalComponent, {ErrorModalData} from '../components/error-modal/error-modal.component';
import {Router} from '@angular/router';
import {APP_ROUTES} from '../app.routes.definition';
import {LogService} from './log.service';
import {AgoraChatNativeService} from './native/agora-chat-native.service';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app.state';
import {
    loginAgoraChatFailure,
    loginAgoraChatSuccess,
    logoutAgoraChat,
    updateAgoraConversationList,
} from '../store/user/user.actions';
import {FreeChatService} from './free-chat.service';
import {NotificationService} from './notification.service';
import {PayloadChat} from '../models/chat.models';
import VirtualBackgroundExtension from 'agora-extension-virtual-background';
import {LogLevel} from "../models/log-level.model";
import {map} from "rxjs/operators";
import {selectUserData} from "../store/user/user.selectors";
import {EnvironmentName} from "../models/app-environment.model";

/**
 * Service for handling Agora-related functionality such as video calls and chat.
 */
@Injectable({
    providedIn: 'root',
})
export class AgoraService {
    static SCREEN_SHARE_POSTFIX = '_screen';

    #appKey = environment.agoraAppKey;
    #appId = environment.agoraAppId;
    #apiUrl = environment.apiUrl;

    #store = inject(Store<AppState>);
    #modalService = inject(ModalService);
    #agoraChatNativeService = inject(AgoraChatNativeService);
    #router = inject(Router);
    #logService = inject(LogService);
    #http = inject(HttpClient);
    #freeChatService = inject(FreeChatService);
    #notificationService = inject(NotificationService);

    videoEngine!: IAgoraRTCClient;
    videoSettings: ClientConfig = {mode: 'rtc', codec: 'vp9'};

    screenEngine?: IAgoraRTCClient | null;
    screenSettings: ClientConfig = {mode: 'rtc', codec: 'vp9'};

    cameras: MediaDeviceInfo[] = [];
    #chatConnection?: AgoraChat.Connection | null;
    virtualBackgroundExtension?: VirtualBackgroundExtension | null

    onTextMessage$ = new Subject<AgoraChat.TextMsgBody>();

    consentPath = APP_ROUTES.SETTINGS_CONSENT();

    constructor() {
        this.virtualBackgroundExtension = new VirtualBackgroundExtension();
        if (this.virtualBackgroundExtension.checkCompatibility()) {
            AgoraRTC.registerExtensions([this.virtualBackgroundExtension]);
        } else {
            this.virtualBackgroundExtension = null;
            this.#logService.log("Does not support Agora Virtual Background Extension!");
        }
        if (environment.name != EnvironmentName.prod) {
            switch (environment.logLevel) {
                case LogLevel.all:
                case LogLevel.debug:
                    AgoraRTC.setLogLevel(0);
                    AC.logger.setLevel(1, false, '');
                    break;
                case LogLevel.info:
                    AgoraRTC.setLogLevel(1);
                    AC.logger.setLevel(2, false, '');
                    break;
                case LogLevel.warn:
                    AgoraRTC.setLogLevel(2);
                    AC.logger.setLevel(3, false, '');
                    break;
                case LogLevel.error:
                    AgoraRTC.setLogLevel(3);
                    AC.logger.setLevel(4, false, '');
                    break;
                default:
                    AgoraRTC.setLogLevel(4);
                    AC.logger.setLevel(5, false, '');
                    break;
            }
        } else {
            AgoraRTC.setLogLevel(4);
            AC.logger.setLevel(5, false, '');
        }
    }

    createClientVideoEngine(): Promise<void> {
        return new Promise((resolve, reject) => {
            AgoraRTC.getCameras()
                .then((devices) => {
                    this.cameras = devices.filter(
                        (device) => device.kind === 'videoinput'
                    );
                    this.videoEngine = AgoraRTC.createClient(
                        this.videoSettings
                    );
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                    this.#modalService.open<ErrorModalData, ErrorModalComponent>(ErrorModalComponent, {
                        data: {
                            message:
                                'Non è possibile partecipare alla videochiamata, controlla i permessi della fotocamera.',
                            onCloseCallback: () => {
                                this.#router.navigate([this.consentPath]);
                            },
                        },
                    });
                });
        });
    }

    createClientScreenEngine() {
        this.screenEngine = AgoraRTC.createClient(
            this.screenSettings
        );
    }

    createChatConnection(userId: string) {
        if (this.#chatConnection == null) {
            this.#chatConnection = new AC.connection({appKey: this.#appKey});
            this.#chatConnection!.addEventHandler('connection&message', {
                onConnected: async () => {
                    this.#logService.log('Chat connected');
                    this.#store.dispatch(loginAgoraChatSuccess());
                    this.#store.dispatch(updateAgoraConversationList());
                },
                onDisconnected: () => {
                    this.#logService.log('Chat disconnected');
                    this.#store.dispatch(logoutAgoraChat());
                },
                onTextMessage: (message) => {
                    this.#logService.log('Chat onTextMessage', message);
                    this.onTextMessage$.next(message);

                    if (message.from && this.#router.url.toLowerCase() !== APP_ROUTES.MESSAGING_ID(false, message.from).toLowerCase()) {
                        firstValueFrom(this.#freeChatService.getConversations()).then(conversations => {
                                for (const conversation of conversations) {
                                    if (conversation.contactId.toLowerCase() === message.from?.toLowerCase()) {
                                        this.#notificationService.open({
                                            data: {
                                                title: conversation.coachName,
                                                subTitle: message.msg,
                                                imageUrl: conversation.coachImageURL,
                                            },
                                            actions: {
                                                click: (remoteMessage) => {
                                                    let payloadForChat: PayloadChat = {
                                                        userId: conversation.contactId,
                                                        userName: conversation.coachName,
                                                        userAvatar:
                                                        conversation.coachImageURL,
                                                    };

                                                    this.#router.navigate(
                                                        [
                                                            APP_ROUTES.MESSAGING_ID(
                                                                false,
                                                                conversation.contactId
                                                            ),
                                                        ],
                                                        {
                                                            state: {
                                                                data: payloadForChat,
                                                            },
                                                        }
                                                    );
                                                    return true;
                                                },
                                            },
                                        });
                                        break;
                                    }
                                }
                            }
                        );
                    }
                },
                onError: (error) => {
                    this.#logService.error('Chat error:', error);
                    // @ts-ignore
                    if ([AC.statusCode.WEBIM_CONNCTION_AUTH_ERROR, AC.statusCode.WEBIM_CONNECTION_ERROR].includes(error.type)) {
                        this.#store.dispatch(loginAgoraChatFailure({
                            error: new Error(`Type: ${error.type}, Message: ${error.message}`)
                        }));
                    }
                },
                onTokenExpired: () => {
                    this.#logService.log('Chat token expired');
                },
                onTokenWillExpire: () => {
                    if (this.#chatConnection) {
                        this.#logService.log('Chat token will expire. Trying to get a new token.');
                        this.fetchChatAgoraToken().subscribe(newTokenResult => {
                                this.#logService.log('newTokenResult', newTokenResult);
                                this.closeChatConnection();
                                this.chatLoginWithAgoraToken(userId, newTokenResult.token).subscribe({
                                    next: loginResult => {
                                        this.#logService.log('Successfully logged in with new Agora chat token', loginResult);
                                    },
                                    error: error => {
                                        this.#logService.error('Failed to login with new Agora chat token error:', error);
                                    }
                                });
                            }
                        );
                    }
                },
            });
        }
    }

    /**
     * Fetches the video access key for joining a meeting.
     * @param meetingId - The ID of the meeting.
     * @param screenShare - Whether the screen is being shared.
     * @returns An Observable that emits the video access key.
     */
    fetchVideoAccessKey(meetingId: string, screenShare = false): Observable<AgoraRtcTokenDTO> {
        return this.#http.get<AgoraRtcTokenDTO>(
            `${this.#apiUrl}/agora/rtc/${meetingId}/token?screenShare=${screenShare}`
        );
    }

    /**
     * Joins a meeting with the specified parameters.
     * @param videoCallTracks - The video call tracks.
     * @param meetingId - The ID of the meeting.
     * @param sfAccountId - The Salesforce ID.
     * @param token - The access token for joining the meeting.
     * @returns An Observable that emits the video call tracks.
     */
    joinMeeting(
        videoCallTracks: VideoCallTracks,
        meetingId: string,
        sfAccountId: string,
        token: string
    ) {
        return from(
            this.videoEngine!.join(this.#appId, meetingId, token, sfAccountId)
        ).pipe(
            switchMap(async () => {
                videoCallTracks.localAudio = await AgoraRTC.createMicrophoneAudioTrack();
                videoCallTracks.localVideo = await AgoraRTC.createCameraVideoTrack();

                const tracks = [];
                if (videoCallTracks.localAudio) {
                    tracks.push(videoCallTracks.localAudio);
                }
                if (videoCallTracks.localVideo) {
                    tracks.push(videoCallTracks.localVideo);
                }

                await this.videoEngine!.publish(tracks);

                return videoCallTracks;
            })
        );
    }

    /**
     * Leaves the meeting and cleans up resources.
     * @param videoCallTracks - The video call tracks.
     * @returns An Observable that completes when the meeting is left.
     */
    leaveMeeting(videoCallTracks: VideoCallTracks) {
        // Destroy the local audio and video tracks.
        videoCallTracks.localAudio?.close();
        videoCallTracks.localVideo?.close();

        // Remove the containers you created for the local video and remote video.
        const promises = [this.videoEngine.leave()];

        const stopScreenSharePromise = this.stopScreenShare(videoCallTracks);
        if (stopScreenSharePromise) {
            promises.push(stopScreenSharePromise);
        }
        const leaveMeeting$ = from(Promise.all(promises));

        videoCallTracks.localVideo = null;
        videoCallTracks.localAudio = null;
        videoCallTracks.remoteParticipants = {};

        return leaveMeeting$;
    }

    setCamera(cameraVideoTrack: ICameraVideoTrack, frontCamera: boolean) {
        const cameraMode: {
            facingMode: 'user' | 'environment';
        } = {facingMode: frontCamera ? 'user' : 'environment'};

        return cameraVideoTrack.setDevice(cameraMode);
    }

    enableMicrophone(microphoneAudioTrack: IMicrophoneAudioTrack, enabled: boolean) {
        return microphoneAudioTrack.setEnabled(enabled);
    }

    enableCamera(cameraVideoTrack: ICameraVideoTrack, enabled: boolean) {
        return cameraVideoTrack.setEnabled(enabled);
    }

    screenShare(
        videoCallTracks: VideoCallTracks,
        meetingId: string,
        sfAccountId: string,
        token: string
    ) {
        this.createClientScreenEngine();
        const screenEngine = this.screenEngine!;
        return from(
            screenEngine.join(this.#appId, meetingId, token, sfAccountId + AgoraService.SCREEN_SHARE_POSTFIX)
        ).pipe(
            switchMap( () => {
              return from(AgoraRTC.createScreenVideoTrack({})).pipe(
                  switchMap((tracks) => {
                      if (tracks instanceof Array) {
                          videoCallTracks.screenVideo = tracks[0];
                          videoCallTracks.screenAudio = tracks[1];
                      } else {
                          videoCallTracks.screenVideo = tracks;
                      }

                      // listen for browser screen share track-ended to force stop the screen share state,
                      // for example when the user stops sharing the screen
                      // using the browser stop screen share button
                      videoCallTracks.screenVideo?.on('track-ended', () => {
                          this.stopScreenShare(videoCallTracks);
                      });

                      return from(screenEngine.publish(tracks)).pipe(
                          switchMap((tracks) => {
                              return of(videoCallTracks);
                          })
                      );
                  })
              )
            }),
            catchError((error) => {
                this.stopScreenShare(videoCallTracks);
                throw error;
            })
        );
    }

    stopScreenShare(videoCallTracks: VideoCallTracks) {
        // Destroy the screen share tracks.
        videoCallTracks.screenAudio?.close();
        videoCallTracks.screenVideo?.close();
        const promise = this.screenEngine?.leave();

        videoCallTracks.screenVideo = null;
        videoCallTracks.screenAudio = null;
        this.screenEngine = null;

        return promise;
    }

    //
    // Chat functionality
    //

    /**
     * Fetches the chat agora token for joining a chat
     * @returns An Observable that emits the agora chat token.
     */
    fetchChatAgoraToken(): Observable<AgoraChatTokenDTO> {
        return this.#http.get<AgoraChatTokenDTO>(
            `${this.#apiUrl}/agora/chat/token`
        );
    }

    chatLoginWithAgoraToken(userId: string, token: string) {
        if (this.#chatConnection?.isOpened()) {
            return of({
                accessToken: this.#chatConnection!.token,
            } as AgoraChat.LoginResult);
        }

        if (this.#agoraChatNativeService.isAvailable()) {
            this.#agoraChatNativeService.loginWithAgoraToken({
                userId: userId,
                agoraToken: token,
            }).catch(reason => {
                this.#logService.error(reason);
            })
        }
        return from(
            this.#chatConnection!.open({
                user: userId,
                agoraToken: token,
            })
        );
    }

    getConversationList() {
        return combineLatest([
            from(this.#chatConnection?.getConversationlist() ?? of(null)),
            this.#store.select(selectUserData).pipe(first())
        ]).pipe(
            map(([conversationList, user]) => {
                if (user == null) return [];

                const channelInfos = conversationList?.data?.channel_infos ?? [];
                const conversations: AgoraConversation[] = [];
                for (const channelInfo of channelInfos) {
                    const lastMessage = channelInfo.lastMessage as AgoraChat.TextMsgBody;
                    channelInfo.unread_num
                    conversations.push({
                        userId: user!.sfAccountId.toLowerCase() === lastMessage.from?.toLowerCase() ? lastMessage.to : (lastMessage.from ?? ''),
                        messages: [lastMessage],
                        unreadNum: channelInfo.unread_num
                    });
                }
                return conversations;
            }));
    }

    closeChatConnection() {
        this.#chatConnection?.close();
    }

    async getHistoryMessages(options: {
        targetId: string;
        chatType?: 'singleChat' | 'groupChat';
        searchDirection?: 'down' | 'up';
        cursor?: number | string | null;
        pageSize?: number;
    }) {
        return this.#chatConnection?.getHistoryMessages({
            targetId: options.targetId,
            chatType: options.chatType,
            searchDirection: options.searchDirection,
            cursor: options.cursor,
            pageSize: options.pageSize
        });
    }

    async sendMessage(message: string, toUserId: string, fromUserId: string) {
        let msg = AC.message.create({
            type: 'txt',
            msg: message,
            to: toUserId,
            chatType: 'singleChat',
            from: fromUserId, // Assumed to be the logged-in user ID
        });

        const result = await this.#chatConnection!.send(msg);

        // also, send the message to Salesforce trigger for notification purpose only
        this.#http
            .post(`${this.#apiUrl}/agora/chat/message`, {
                text: message,
                recipientId: toUserId,
            })
            .subscribe({
                next: (res) => {
                    this.#logService.log('message sent to Salesforce', res);
                },
                error: (err) => {
                    // we don't care in case of error
                    this.#logService.error(err);
                },
            });

        return result;
    }

    async sendReadChatReceipt(toUserId: string, fromUserId: string) {
        let msg = AC.message.create({
            type: 'channel',
            to: toUserId,
            chatType: 'singleChat',
            from: fromUserId, // Assumed to be the logged-in user ID
        });

        return await this.#chatConnection!.send(msg);
    }
}
