import {inject, Injectable, NgZone} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EMPTY, from, of, tap} from 'rxjs';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import * as UserActions from './user.actions';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../services/auth.service';
import {jwtDecode} from "jwt-decode";
import {LoginResponseDecoded} from "../../models/responses/login-response-decoded.model";
import {ModalService} from "../../services/modal.service";
import ErrorModalComponent, {ErrorModalData} from "../../components/error-modal/error-modal.component";
import {resetCancelSubscription} from "../cancel-subscription/cancel-subscription.actions";
import {clearCreateOrder} from "../create-order/create-order.actions";
import {APP_ROUTES} from "../../app.routes.definition";
import {LogService} from "../../services/log.service";
import {AgoraService} from "../../services/agora.service";
import {AgoraChatNativeService} from "../../services/native/agora-chat-native.service";
import {Store} from "@ngrx/store";
import {AppState} from "../app.state";
import {selectUserData} from "./user.selectors";
import {TryTerraService} from "../../services/try-terra.service";
import {TypedAction} from "@ngrx/store/src/models";
import {HttpErrorResponse, HttpStatusCode} from "@angular/common/http";
import * as Sentry from "@sentry/angular";
import {EnvironmentName} from "../../models/app-environment.model";
import {VideoCallService} from "../../services/video-call.service";

@Injectable()
export class UserEffects {
    #actions$ = inject(Actions);
    #userService = inject(UserService);
    #authService = inject(AuthService);
    #agoraService = inject(AgoraService);
    #agoraChatNativeService = inject(AgoraChatNativeService);
    #router = inject(Router);
    #zone = inject(NgZone);
    #modalService = inject(ModalService);
    #logService = inject(LogService);
    #tryTerraService = inject(TryTerraService);
    #store = inject(Store<AppState>);
    #videoCallService = inject(VideoCallService);

    login$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.login),
        mergeMap((action) => {
                return this.#authService
                    .requestAccessToken(action.email.toLowerCase(), action.password)
                    .pipe(mergeMap(loginResponse => {
                            const decoded = jwtDecode<LoginResponseDecoded>(
                                loginResponse.access_token
                            );
                            if (
                                !decoded.isTwoFactorAuthenticatedEnabled ||
                                (decoded.isTwoFactorAuthenticated &&
                                    decoded.isTwoFactorAuthenticatedEnabled)
                            ) {
                                this.#authService.setAuthenticated(
                                    loginResponse.access_token
                                );
                                return this.#userService.getAccount().pipe(
                                    mergeMap((user) => {
                                        return of(UserActions.loginSuccess({
                                            data: user,
                                        }));
                                    }),
                                    catchError((error) => {
                                        return of(UserActions.loginFailure({error}));
                                    })
                                );
                            } else {
                                this.#authService.setOTPToken(
                                    loginResponse.access_token
                                );

                                this.#router.navigate([APP_ROUTES.LOGIN_VERIFY_OTP()]);
                            }
                            return EMPTY;
                        }),
                        catchError((error) => {
                            return of(UserActions.loginFailure({error}));
                        }));
            }
        )));

    loginOtp$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.loginOtp),
        mergeMap((action) => {
                return this.#authService
                    .twoFactorAuthentication(action.otp)
                    .pipe(mergeMap(loginResponse => {
                            this.#authService.setAuthenticated(
                                loginResponse.access_token
                            );

                            return this.#userService.getAccount().pipe(
                                mergeMap((user) => {
                                    return of(UserActions.loginSuccess({
                                        data: user,
                                    }));
                                }),
                                catchError((error) => {
                                    return of(UserActions.loginFailure({error}));
                                })
                            );
                        }),
                        catchError((error) => {
                            return of(UserActions.loginOtpFailure({error}));
                        }));
            }
        )));

    loginRecoveryCode$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.loginRecoveryCode),
        mergeMap((action) => {
                return this.#authService
                    .loginWithRecoveryCode(action.recoveryCode)
                    .pipe(mergeMap(loginResponse => {
                            this.#authService.setAuthenticated(
                                loginResponse.access_token
                            );

                            return this.#userService.getAccount().pipe(
                                mergeMap((user) => {
                                    return of(UserActions.loginSuccess({
                                        data: user,
                                    }));
                                }),
                                catchError((error) => {
                                    return of(UserActions.loginFailure({error}));
                                })
                            );
                        }),
                        catchError((error) => {
                            return of(UserActions.loginOtpFailure({error}));
                        }));
            }
        )));

    loginProvider$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.loginProvider),
        mergeMap((action) => {
                this.#authService.setAuthenticated(
                    action.accessToken
                );

                return this.#userService.getAccount().pipe(
                    mergeMap((user) => {
                        return of(UserActions.loginSuccess({
                            data: user,
                        }));
                    }),
                    catchError((error) => {
                        return of(UserActions.loginFailure({error}));
                    })
                );
            }
        )));

    loginSuccess$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.loginSuccess),
        tap(payload => {
            this.#authService.clearSessionStorage();
            this.#zone.run(() => this.#router.navigateByUrl('/home'));
            Sentry.setUser({
                id: payload.data.id,
                email: environment.name === EnvironmentName.prod ? undefined : payload.data.email,
            });
        }),
        switchMap(action => {
            return [UserActions.loginAgoraChat({userId: action.data.sfAccountId})];
        })
    ));

    loginFailure$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.loginFailure),
        tap(payload => {
            this.#logService.error(payload.error);
            this.#authService.clearLocalStorage();
            this.#authService.clearSessionStorage();
            this.#zone.run(() => this.#router.navigateByUrl(APP_ROUTES.LOGIN()));
        })
    ), {dispatch: false});

    loginAgoraChat$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.loginAgoraChat),
        mergeMap((action) => {
                this.#agoraService.createChatConnection(action.userId);
                return this.#agoraService.fetchChatAgoraToken().pipe(
                    mergeMap(agoraChatTokenDto => {
                        return this.#agoraService
                            .chatLoginWithAgoraToken(action.userId, agoraChatTokenDto.token)
                            .pipe(mergeMap(loginResponse => {
                                    return of(UserActions.loginAgoraChatSuccess());
                                }),
                                catchError((error) => {
                                    return of(UserActions.loginAgoraChatFailure({error}));
                                }));
                    })
                );
            }
        )));

    loginAgoraChatFailure$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.loginAgoraChatFailure),
        tap(payload => {
            this.#logService.error(payload.error);
        })
    ), {dispatch: false});

    logout$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.logout),
        tap(payload => {
            this.#authService.clearLocalStorage();
            this.#authService.clearSessionStorage();
            this.#agoraService.closeChatConnection();
            if (this.#agoraChatNativeService.isAvailable()) {
                this.#agoraChatNativeService.logout().catch(reason => {
                    this.#logService.error(reason);
                });
            }
            this.#zone.run(() => this.#router.navigateByUrl(APP_ROUTES.LOGIN()));
            Sentry.setUser(null);
        }),
        switchMap(payload => {
            return this.#userService.logout().pipe(
                catchError((err, caught) => {
                    this.#logService.error(err);
                    return EMPTY;
                })
            );
        }),
        switchMap(action => {
            return [resetCancelSubscription(), clearCreateOrder(), UserActions.logoutAgoraChat()];
        })
    ));

    fetchUser$ = createEffect(() =>
        this.#actions$.pipe(
            ofType(UserActions.fetchUser),
            mergeMap((action) =>
                this.#userService.getAccount().pipe(
                    map((user) => {
                        return UserActions.fetchUserSuccess({
                            data: user,
                        });

                    }),
                    catchError((error) => {
                        return of(UserActions.fetchUserFailure({error}));
                    })
                )
            )
        )
    );

    fetchUserSuccess$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.fetchUserSuccess),
        tap(payload => {
            Sentry.setUser({
                id: payload.data.id,
                email: environment.name === EnvironmentName.prod ? undefined : payload.data.email,
            });
        })
    ), {dispatch: false});

    fetchUserFailure$ = createEffect(() =>
        this.#actions$.pipe(
            ofType(UserActions.fetchUserFailure),
            switchMap((payload) => {
                    this.#logService.error(payload.error);
                    let shouldLogout = payload.error instanceof HttpErrorResponse &&
                        (payload.error.status === HttpStatusCode.NotFound ||
                            (payload.error.status === HttpStatusCode.Unauthorized && payload.error.error && payload.error.error.error === 'JsonWebTokenError'));
                    if (shouldLogout) {
                        return of(UserActions.logout());
                    }
                    return EMPTY;
                }
            )
        )
    );

    updateUser$ = createEffect(() =>
        this.#actions$.pipe(
            ofType(UserActions.updateUser),
            switchMap((action) =>
                this.#userService.updateUser(action.data).pipe(
                    map(() =>
                        UserActions.updateUserSuccess({data: action.data})
                    ),
                    catchError((error) =>
                        of(UserActions.updateUserFailure({error}))
                    )
                )
            )
        )
    );

    updateUserFailure$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.updateUserFailure),
        tap(payload => {
            this.#logService.error(payload.error);
            this.#zone.run(() => {
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(ErrorModalComponent, {
                    data: {
                        error: payload.error,
                        message: 'Errore durante il salvataggio dei dati.',
                    }
                });
            });
        })
    ), {dispatch: false});

    updateUserBillingInfo$ = createEffect(() =>
        this.#actions$.pipe(
            ofType(UserActions.updateUserBillingInfo),
            switchMap((action) =>
                this.#userService.updateUserBillingInfo(action.data).pipe(
                    map(() =>
                        UserActions.updateUserBillingInfoSuccess({data: action.data})
                    ),
                    catchError((error) =>
                        of(UserActions.updateUserBillingInfoFailure({error}))
                    )
                )
            )
        )
    );

    updateUserBillingInfoFailure$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.updateUserBillingInfoFailure),
        tap(payload => {
            this.#logService.error(payload.error);
            this.#zone.run(() => {
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(ErrorModalComponent, {
                    data: {
                        error: payload.error,
                        message: 'Errore durante il salvataggio dei dati.',
                    }
                });
            });
        })
    ), {dispatch: false});

    updateUserAvatar$ = createEffect(() =>
        this.#actions$.pipe(
            ofType(UserActions.updateUserAvatar),
            switchMap((action) =>
                this.#userService.updateAvatar(action.avatar).pipe(
                    mergeMap(() =>
                        [UserActions.updateUserAvatarSuccess(), UserActions.fetchUser()]
                    ),
                    catchError((error) =>
                        of(UserActions.updateUserAvatarFailure({error}))
                    )
                )
            )
        )
    );

    updateUserAvatarFailure$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.updateUserAvatarFailure),
        tap(payload => {
            this.#logService.error(payload.error);
            this.#zone.run(() => {
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(ErrorModalComponent, {
                    data: {
                        error: payload.error
                    }
                });
            });
        })
    ), {dispatch: false});

    deleteUser$ = createEffect(() =>
        this.#actions$.pipe(
            ofType(UserActions.deleteUser),
            switchMap((action) =>
                this.#userService.anonymizeUser(action.password).pipe(
                    mergeMap(() =>
                        [UserActions.deleteUserSuccess(), UserActions.logout()]
                    ),
                    catchError((error) =>
                        of(UserActions.deleteUserFailure({error}))
                    )
                )
            )
        )
    );

    deleteUserFailure$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.deleteUserFailure),
        tap(payload => {
            this.#logService.error(payload.error);
            this.#zone.run(() => {
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(ErrorModalComponent, {
                    data: {
                        error: payload.error
                    }
                });
            });
        })
    ), {dispatch: false});

    initializeTryTerra$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.initializeTryTerra),
        mergeMap((action) => {
                return this.#store.select(selectUserData).pipe(switchMap(user => {
                    if (user == null) {
                        return of(UserActions.initializeTryTerraSuccess());
                    }
                    return this.#tryTerraService.initialize(user.id.toString()).pipe(
                        switchMap(() => {
                            const dispatchActions: TypedAction<any>[] = [UserActions.initializeTryTerraSuccess()];
                            if (action.triggerAllDataWebhook) {
                                dispatchActions.push(UserActions.triggerTryTerraWebhook({daysBeforeNow: action.daysBeforeNow ?? 7}));
                            }
                            return dispatchActions;
                        }),
                        catchError((error) => {
                            return of(UserActions.initializeTryTerraFailure({error}));
                        }),
                    );
                }));
            }
        )));

    initializeTryTerraSuccess$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.initializeTryTerraSuccess),
        mergeMap(payload => {
            if (this.#tryTerraService.nativeIsAvailable()) {
                return this.#store.select(selectUserData).pipe(switchMap(
                    user => {
                        if (user != null && !user.hasLoggedMobile) {
                            return this.#userService
                                .setLoggedMobile()
                                .pipe(
                                    switchMap(value => {
                                            return of(UserActions.fetchUser());
                                        }
                                    ),
                                    catchError((error, caught) => {
                                        this.#logService.error(
                                            error
                                        );
                                        this.#modalService.open<
                                            ErrorModalData,
                                            ErrorModalComponent
                                        >(ErrorModalComponent, {
                                            data: {
                                                error: error,
                                            },
                                        });
                                        return EMPTY;
                                    })
                                );
                        }
                        return EMPTY;
                    }
                ));
            }
            return EMPTY;
        })
    ));

    initializeTryTerraFailure$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.initializeTryTerraFailure),
        tap(payload => {
            this.#logService.error(payload.error);
        })
    ), {dispatch: false});

    triggerTryTerraWebhook$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.triggerTryTerraWebhook),
        mergeMap((action) => {
                if (this.#tryTerraService.nativeIsAvailable()) {
                    return this.#tryTerraService.triggerAllDataWebhook(action.daysBeforeNow).pipe(switchMap(
                        dataMessages => {
                            for (const dataMessage of dataMessages) {
                                if (dataMessage == null || !dataMessage.success || dataMessage.error) {
                                    const error = new Error(dataMessage?.error ?? '')
                                    return of(UserActions.triggerTryTerraWebhookFailure({error}));
                                }
                            }
                            return of(UserActions.triggerTryTerraWebhookSuccess());
                        }
                    ));
                }
                return EMPTY;
            }
        )));

    triggerTryTerraWebhookFailure$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.triggerTryTerraWebhookFailure),
        tap(payload => {
            this.#logService.error(payload.error);
        })
    ), {dispatch: false});

    updateAgoraConversationList$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.updateAgoraConversationList),
        mergeMap((action) => {
                return this.#agoraService.getConversationList().pipe(
                    switchMap((conversationList) => {
                        return of(UserActions.updateAgoraConversationListSuccess({conversationList}));
                    }),
                    catchError((error) => {
                        return of(UserActions.updateAgoraConversationListFailure({error}));
                    }),
                );
            }
        )));


    updateAgoraConversationListFailure$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.updateAgoraConversationListFailure),
        tap(payload => {
            this.#logService.error(payload.error);
        })
    ), {dispatch: false});

    joinAgoraVideoCall$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.joinAgoraVideoCall),
        mergeMap(({meetingId}) => {
                return from(this.#videoCallService.joinVideoMeeting(meetingId)).pipe(
                    switchMap(() => {
                        return of(UserActions.joinAgoraVideoCallSuccess());
                    }),
                    catchError((error) => {
                        return of(UserActions.joinAgoraVideoCallFailure({error}));
                    })
                );
            }
        )));

    joinAgoraVideoCallFailure$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.joinAgoraVideoCallFailure),
        switchMap(payload => {
                this.#logService.error(payload.error);
                this.#zone.run(() => {
                    this.#modalService.open<ErrorModalData, ErrorModalComponent>(ErrorModalComponent, {
                        data: {
                            error: payload.error,
                            message: 'Non è stato possibile avviare la videochiamata',
                        }
                    });
                });
                return of(UserActions.leaveAgoraVideoCall({}));
            }
        )));

    leaveAgoraVideoCall$ = createEffect(() => this.#actions$.pipe(
        ofType(UserActions.leaveAgoraVideoCall),
        tap(payload => {
            this.#videoCallService.closeVideoMeeting(payload.meetingId != null && payload.elapsedCallTime != null && payload.elapsedCallTime != null ? {
                meetingId: payload.meetingId!,
                elapsedCallTime: payload.elapsedCallTime!,
                time: payload.callTimeDisplay!
            } : null);
        })
    ), {dispatch: false});
}
