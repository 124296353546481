import {ApplicationRef, ComponentRef, inject, Injectable} from '@angular/core';
import {AgoraService} from "./agora.service";
import {LogService} from "./log.service";
import {firstValueFrom, of, Subject} from "rxjs";
import {LeaveEventData, VideoCallPlayerComponent} from "../components/video-call-player/video-call-player.component";
import {APP_ROUTES} from "../app.routes.definition";
import {Router} from "@angular/router";
import {selectUserAgoraVideoCallIsJoining, selectUserData} from "../store/user/user.selectors";
import {Store} from "@ngrx/store";
import {AppState} from "../store/app.state";
import {AppComponent} from "../app.component";
import {IAgoraRTCRemoteUser, ICameraVideoTrack, IDataChannelConfig} from "agora-rtc-sdk-ng";
import {VideoCallTracks} from "../models/agora.models";
import {IVirtualBackgroundProcessor} from "agora-extension-virtual-background";
import {User} from "../models/users.models";
import ErrorModalComponent, {ErrorModalData} from "../components/error-modal/error-modal.component";
import {ModalService} from "./modal.service";

@Injectable({
    providedIn: 'root'
})
export class VideoCallService {
    #agoraService = inject(AgoraService);
    #appRef = inject(ApplicationRef);
    #logService = inject(LogService);
    #router = inject(Router);
    #store = inject(Store<AppState>);
    #modalService = inject(ModalService);

    #currentUser$ = this.#store.select(selectUserData);
    #isJoining$ = this.#store.select(selectUserAgoraVideoCallIsJoining);
    #compRef: ComponentRef<VideoCallPlayerComponent> | null = null;
    #videoCallTracks: VideoCallTracks | null = null;
    #frontCamera = true;
    #virtualBackgroundProcessor?: IVirtualBackgroundProcessor | null = null;

    $onRemoteUserPublishedSubject = new Subject<{
        user: IAgoraRTCRemoteUser,
        mediaType: ("audio" | "video" | "datachannel"),
        config?: (IDataChannelConfig | undefined)
    }>();

    $onRemoteUserUnpublishedSubject = new Subject<{
        user: IAgoraRTCRemoteUser,
        mediaType: ("audio" | "video" | "datachannel"),
        config?: (IDataChannelConfig | undefined)
    }>();

    constructor() {
    }

    get videoCallTracks() {
        return this.#videoCallTracks;
    }

    get frontCamera() {
        return this.#frontCamera;
    }

    get microphoneEnabled() {
        return this.#videoCallTracks?.localAudio?.enabled ?? false;
    }

    get cameraEnabled() {
        return this.#videoCallTracks?.localVideo?.enabled ?? false;
    }

    get cameras() {
        return this.#agoraService.cameras;
    }

    get blurEnabled() {
        return this.#virtualBackgroundProcessor?.enabled ?? false;
    }

    get screenShareEnabled() {
        return this.#videoCallTracks?.screenVideo?.enabled ?? false;
    }

    /**
     * Joins a video meeting with the specified meeting ID.
     * @param meetingId The ID of the meeting to join.
     */
    async joinVideoMeeting(meetingId: string) {
        this.closeVideoMeeting();

        const user = await firstValueFrom(this.#currentUser$);
        if (user == null) {
            return;
        }

        const response = await firstValueFrom(this.#agoraService.fetchVideoAccessKey(meetingId));
        this.$onRemoteUserPublishedSubject = new Subject();
        this.$onRemoteUserUnpublishedSubject = new Subject();
        await this.#agoraService.createClientVideoEngine();
        this.#agoraService.videoEngine.on(
            'user-published',
            (remoteUser, mediaType, config) => this.onRemoteUserPublished(user, remoteUser, mediaType, config)
        );
        this.#agoraService.videoEngine.on(
            'user-unpublished',
            (remoteUser, mediaType, config) => this.onRemoteUserUnpublished(user, remoteUser, mediaType, config)
        );
        await this.loadVideoCallPlayerComponent(meetingId);
        this.#videoCallTracks = {
            remoteParticipants: {},
        };
        await firstValueFrom(this.#agoraService
            .joinMeeting(
                this.#videoCallTracks,
                meetingId,
                user.sfAccountId,
                response.token));

        if (this.#videoCallTracks.localVideo) {
            this.#virtualBackgroundProcessor = this.#agoraService.virtualBackgroundExtension?.createProcessor();
            if (this.#virtualBackgroundProcessor) {
                await this.#virtualBackgroundProcessor.init();
                this.#virtualBackgroundProcessor.setOptions({type: 'blur', blurDegree: 3});
                this.#videoCallTracks.localVideo
                    .pipe(this.#virtualBackgroundProcessor)
                    .pipe(this.#videoCallTracks.localVideo.processorDestination);
            }
        }
    }

    async screenShare(meetingId: string) {
        await this.stopScreenShare();

        const user = await firstValueFrom(this.#currentUser$);
        if (user == null || this.#videoCallTracks == null) {
            return;
        }
        const response = await firstValueFrom(this.#agoraService.fetchVideoAccessKey(meetingId, true));

        await firstValueFrom(this.#agoraService
            .screenShare(
                this.#videoCallTracks,
                meetingId,
                user.sfAccountId,
                response.token));
    }

    async stopScreenShare() {
        if (this.#videoCallTracks == null) {
            return;
        }
        await this.#agoraService.stopScreenShare(this.#videoCallTracks);
    }

    closeVideoMeeting(data?: LeaveEventData | null) {
        this.$onRemoteUserPublishedSubject.complete();
        this.$onRemoteUserUnpublishedSubject.complete();
        if (this.#videoCallTracks != null) {
            this.#agoraService.leaveMeeting(this.#videoCallTracks).subscribe({
                next: () => {
                    this.#logService.log('Left the meeting successfully');
                },
                error: (error) => {
                    this.#logService.error('Failed to leave the meeting', error);
                },
            });
        }
        this.#virtualBackgroundProcessor?.release();
        this.#virtualBackgroundProcessor = null;
        this.#videoCallTracks = null;
        const viewRef = this.getViewRef();
        if (this.#compRef == null && data == null) {
            return;
        }
        if (data == null && this.#compRef != null) {
            data = {
                meetingId: this.#compRef.instance.meetingId,
                elapsedCallTime: this.#compRef.instance.elapsedCallTime,
                time: this.#compRef.instance.callTimeDisplay
            };
        }
        this.#compRef?.destroy();
        this.#compRef = null;
        viewRef?.clear();
        if (data != null && data.elapsedCallTime > 30) {
            this.#router.navigate([APP_ROUTES.RATING()], {
                state: {data: data.meetingId},
            });
        }
    }

    switchCamera(options?: { cameraVideoTrack: ICameraVideoTrack, frontCamera?: boolean | null }) {
        this.#frontCamera = options?.frontCamera ?? this.#frontCamera;
        const localVideo = options?.cameraVideoTrack ?? this.#videoCallTracks?.localVideo;
        if (localVideo) {
            return this.#agoraService.setCamera(localVideo, this.#frontCamera);
        }
        return null;
    }

    toggleFrontCamera() {
        this.#frontCamera = !this.#frontCamera;
        const localVideo = this.#videoCallTracks?.localVideo;
        if (localVideo) {
            return this.switchCamera({cameraVideoTrack: localVideo, frontCamera: this.#frontCamera});
        }
        return null;
    }

    toggleMicrophone() {
        return this.enableMicrophone(!this.microphoneEnabled);
    }

    enableMicrophone(enabled: boolean) {
        const localAudio = this.#videoCallTracks?.localAudio;
        if (localAudio) {
            return this.#agoraService.enableMicrophone(localAudio, enabled);
        }
        return null;
    }

    toggleCamera() {
        return this.enableCamera(!this.cameraEnabled);
    }

    enableCamera(enabled: boolean) {
        const localVideo = this.#videoCallTracks?.localVideo;
        if (localVideo) {
            return this.#agoraService.enableCamera(localVideo, enabled);
        }
        return null;
    }

    toggleBlur() {
        return this.enableBlur(!this.blurEnabled);
    }

    enableBlur(enabled: boolean) {
        if (this.#virtualBackgroundProcessor) {
            if (enabled) {
                return this.#virtualBackgroundProcessor.enable();
            } else {
                return this.#virtualBackgroundProcessor.disable();
            }
        }
    }

    private getViewRef() {
        if (this.#appRef.components.length == 0) {
            return null;
        }
        return (this.#appRef.components[0].instance as AppComponent).viewRef
    }

    /**
     * Loads the VideoCallPlayerComponent and sets the necessary properties.
     */
    private async loadVideoCallPlayerComponent(meetingId: string) {
        const viewRef = this.getViewRef();
        if (viewRef == null && viewRef!.length == 0) {
            return;
        }
        viewRef!.clear();

        this.#compRef = viewRef!.createComponent(VideoCallPlayerComponent);
        const instance = this.#compRef.instance;
        instance.meetingId = meetingId;
        this.#compRef?.changeDetectorRef.detectChanges();
    }

    private onRemoteUserPublished(localUser: User, remoteUser: IAgoraRTCRemoteUser, mediaType: ("audio" | "video" | "datachannel"), config?: (IDataChannelConfig | undefined)) {
        this.#logService.log('onRemoteUserPublished', localUser, remoteUser, mediaType);

        if (remoteUser.uid === localUser.sfAccountId + AgoraService.SCREEN_SHARE_POSTFIX) {
            // Ignore the local screen sharing user
            return;
        }
        this.#agoraService.videoEngine
            ?.massSubscribe([
                {
                    user: remoteUser,
                    mediaType: mediaType as "audio" | "video"
                },
            ])
            .then(() => {
                this.$onRemoteUserPublishedSubject.next({user: remoteUser, mediaType, config});
            });
    }

    private onRemoteUserUnpublished(localUser: User, remoteUser: IAgoraRTCRemoteUser, mediaType: ("audio" | "video" | "datachannel"), config?: (IDataChannelConfig | undefined)) {
        this.#logService.log('onRemoteUserUnpublished', localUser, remoteUser, mediaType);

        if (remoteUser.uid === localUser.sfAccountId + AgoraService.SCREEN_SHARE_POSTFIX) {
            // Ignore the local screen sharing user
            return;
        }

        this.#agoraService.videoEngine
            ?.massUnsubscribe([
                {
                    user: remoteUser,
                    mediaType: mediaType as "audio" | "video"
                },
            ])
            .then(() => {
                this.$onRemoteUserUnpublishedSubject.next({user: remoteUser, mediaType, config});
            });
    }
}
