import {Component, DestroyRef, inject, OnDestroy} from '@angular/core';
import {ModalService} from '../../services/modal.service';
import {Router} from '@angular/router';
import {HealthProviderName, TryTerraSupportedHealthProvider} from "../../models/try-terra.models";
import {TryTerraService} from "../../services/try-terra.service";
import {LogService} from '../../services/log.service';
import {NativeAPIService, TargetPlatform} from "../../services/native/native-api.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {InfoModalComponent, InfoModalData} from "../info-modal/info-modal.component";
import ErrorModalComponent, {ErrorModalData} from "../error-modal/error-modal.component";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/app.state";
import {initializeNativeTryTerra, fetchTryTerraUserInfo} from "../../store/user/user.actions";
import {selectUserTryTerraIsNativeInitializing} from "../../store/user/user.selectors";
import {AsyncPipe} from "@angular/common";
import {APP_ROUTES} from "../../app.routes.definition";

export interface HealthProviderConnectModalData {
    provider: TryTerraSupportedHealthProvider;
    isConnecting: boolean;
}

@Component({
    selector: 'app-health-provider-connect-modal',
    standalone: true,
    imports: [
        AsyncPipe
    ],
    templateUrl: './health-provider-connect-modal.component.html',
})
export class HealthProviderConnectModalComponent implements HealthProviderConnectModalData {
    #modalService = inject(ModalService);
    #router = inject(Router);
    #tryTerraService = inject(TryTerraService);
    #logService = inject(LogService);
    #nativeAPIService = inject(NativeAPIService);
    #destroyRef = inject(DestroyRef);
    #store = inject(Store<AppState>);

    tryTerraIsNativeInitializing$ = this.#store.select(selectUserTryTerraIsNativeInitializing);

    HealthProviderName = HealthProviderName;
    provider!: TryTerraSupportedHealthProvider;
    isConnecting!: boolean;
    isRequestingAuth = false;
    isRequestingDeauth = false;

    constructor() {
    }

    async authWithProvider() {
        if (this.isRequestingAuth || this.isRequestingDeauth) {
            return;
        }
        this.isRequestingAuth = true;

        const platform = await this.#nativeAPIService.defaultTargetPlatform();
        if (this.provider === TryTerraSupportedHealthProvider.HEALTH_CONNECT && platform !== TargetPlatform.android) {
            this.#modalService.open<InfoModalData, InfoModalComponent>(InfoModalComponent, {
                data: {
                    title: `Non è possibile connettersi a "${HealthProviderName[this.provider]}" tramite ${
                        platform === TargetPlatform.web ? 'browser' : 'questo dispobitivo.'
                    }.`,
                    message: `"${HealthProviderName[this.provider]}" è disponibile solo usando l\'App scaricabile dal Google Play Store e solo su dispositivi Android.`,
                },
            });
            this.isRequestingAuth = false;
            return;
        } else if (this.provider === TryTerraSupportedHealthProvider.APPLE && platform !== TargetPlatform.iOS) {
            this.#modalService.open<InfoModalData, InfoModalComponent>(InfoModalComponent, {
                data: {
                    title: `Non è possibile connettersi a "${HealthProviderName[this.provider]}" tramite ${
                        platform === TargetPlatform.web ? 'browser' : 'questo dispobitivo.'
                    }.`,
                    message: `"${HealthProviderName[this.provider]}" è disponibile solo usando l\'App scaricabile dall\' Apple Store e solo su dispositivi Apple.`,
                },
            });
            this.isRequestingAuth = false;
            return;
        } else if ([TryTerraSupportedHealthProvider.HEALTH_CONNECT, TryTerraSupportedHealthProvider.APPLE].includes(this.provider)) {
            this.#modalService.closeAll();
            this.#router.navigate([APP_ROUTES.ASK_IMPORT_HEALTH_DATA()]);
            this.isRequestingAuth = false;
            return;
        }

        this.#tryTerraService.authWithProvider(this.provider)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe({
                next: value => {
                    window.open(value.auth_url, '_blank');
                    this.#modalService.closeAll();
                    this.isRequestingAuth = false;
                },
                error: error => {
                    this.#logService.error(error);
                    this.#modalService.closeAll();
                    this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                        ErrorModalComponent,
                        {
                            data: {
                                error: error,
                                message: `Non è stato possibile connettersi a ${HealthProviderName[this.provider]}.`
                            },
                        }
                    );
                    this.isRequestingAuth = false;
                }
            });
    }

    deauthProvider() {
        if (this.isRequestingAuth || this.isRequestingDeauth) {
            return;
        }
        this.isRequestingDeauth = true;

        this.#tryTerraService.deauthProvider(this.provider)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe({
                next: value => {
                    this.#logService.log(value);
                    this.#modalService.closeAll();
                    this.#modalService.open<InfoModalData, InfoModalComponent>(InfoModalComponent, {
                        data: {
                            title: `Disconnessione a ${HealthProviderName[this.provider]} avvenuta con successo.`
                        },
                    });
                    this.isRequestingDeauth = false;
                    this.#store.dispatch(fetchTryTerraUserInfo({initializeNative: false}));
                },
                error: error => {
                    this.#logService.error(error);
                    this.#modalService.closeAll();
                    this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                        ErrorModalComponent,
                        {
                            data: {
                                error: error,
                                message: `Non è stato possibile disconnettersi a ${HealthProviderName[this.provider]}.`
                            },
                        }
                    );
                    this.isRequestingDeauth = false;
                }
            });
    }

    onClose() {
        this.#modalService.close();
    }
}
