import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { filter, Observable, switchMap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.state';
import {
    selectUserFirstPendingOrder,
    selectUserHasBasePlan,
    selectUserHasFeature, selectUserHasLoginProvider,
    selectUserHasPendingOrders,
    selectUserIsLoading, selectUserTryTerraIsGettingUserInfo, selectUserTryTerraUserInfo,
} from '../store/user/user.selectors';
import { map } from 'rxjs/operators';
import { ProductFeature } from '../models/product.model';
import {APP_ROUTES} from "../app.routes.definition";
import {NativeAPIService} from "../services/native/native-api.service";

export const AuthGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    return inject(AuthService).isAuthenticated()
        ? true
        : inject(Router).createUrlTree([APP_ROUTES.AUTH()]);
};

export const NoAuthGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    return inject(AuthService).isAuthenticated()
        ? inject(Router).createUrlTree([APP_ROUTES.HOME()])
        : true;
};

export const UserHasBasePlanGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    const router = inject(Router);
    const store = inject(Store<AppState>);
    return store.select(selectUserIsLoading).pipe(
        filter((isLoading) => !isLoading),
        switchMap((isLoading) => {
            return store.select(selectUserHasBasePlan).pipe(
                map((hasBasePlan) => {
                    if (!hasBasePlan) {
                        return router.createUrlTree([APP_ROUTES.HOME()]);
                    }
                    return true;
                })
            );
        })
    );
};

export const UserHasNotBasePlanGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    const router = inject(Router);
    const store = inject(Store<AppState>);
    return store.select(selectUserIsLoading).pipe(
        filter((isLoading) => !isLoading),
        switchMap((isLoading) => {
            return store.select(selectUserHasBasePlan).pipe(
                map((hasBasePlan) => {
                    if (hasBasePlan) {
                        return router.createUrlTree([APP_ROUTES.HOME()]);
                    }
                    return true;
                })
            );
        })
    );
};

export const UserHasProductFeatureGuard = (
    feature: ProductFeature
): CanActivateFn => {
    return (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree => {
        const router = inject(Router);
        const store = inject(Store<AppState>);
        return store.select(selectUserIsLoading).pipe(
            filter((isLoading) => !isLoading),
            switchMap((isLoading) => {
                return store.select(selectUserHasFeature(feature)).pipe(
                    map((hasProductFeature) => {
                        if (!hasProductFeature) {
                            return router.createUrlTree([APP_ROUTES.HOME()]);
                        }
                        return true;
                    })
                );
            })
        );
    };
};

export const UserHasPendingOrderGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    const router = inject(Router);
    const store = inject(Store<AppState>);
    return store.select(selectUserIsLoading).pipe(
        filter((isLoading) => !isLoading),
        switchMap((isLoading) => {
            return store.select(selectUserHasPendingOrders).pipe(
                map((hasPendingOrders) => {
                    if (!hasPendingOrders) {
                        return router.createUrlTree([APP_ROUTES.HOME()]);
                    }
                    return true;
                })
            );
        })
    );
};

export const UserHasLoginProvider: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    const router = inject(Router);
    const store = inject(Store<AppState>);
    return store.select(selectUserIsLoading).pipe(
        filter((isLoading) => !isLoading),
        switchMap((isLoading) => {
            return store.select(selectUserHasLoginProvider).pipe(
                map((hasLoginProvider) => {
                    if (!hasLoginProvider) {
                        return router.createUrlTree([APP_ROUTES.HOME()]);
                    }
                    return true;
                })
            );
        })
    );
};

export const UserHasNotLoginProvider: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    const router = inject(Router);
    const store = inject(Store<AppState>);
    return store.select(selectUserIsLoading).pipe(
        filter((isLoading) => !isLoading),
        switchMap((isLoading) => {
            return store.select(selectUserHasLoginProvider).pipe(
                map((hasLoginProvider) => {
                    if (hasLoginProvider) {
                        return router.createUrlTree([APP_ROUTES.HOME()]);
                    }
                    return true;
                })
            );
        })
    );
};

export const IsMobileGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    return inject(NativeAPIService).isAvailable() ? true : inject(Router).createUrlTree([APP_ROUTES.HOME()]);
};

export const RedirectToPendingOrderIfAnyGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    const router = inject(Router);
    const store = inject(Store<AppState>);
    return store.select(selectUserIsLoading).pipe(
        filter((isLoading) => !isLoading),
        switchMap((isLoading) => {
            return store.select(selectUserFirstPendingOrder).pipe(
                map((pendingOrder) => {
                    if (pendingOrder) {
                        return router.createUrlTree([APP_ROUTES.PENDING_ORDER(false, pendingOrder.id)]);
                    }
                    return true;
                })
            );
        })
    );
};
