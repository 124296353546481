import {Component, inject, OnInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { CommonModule } from '@angular/common';
import {
    selectUserAgoraVideoCallIsActive, selectUserAgoraVideoCallIsJoining,
    selectUserData,
    selectUserHasBasePlan,
} from '../../store/user/user.selectors';
import {catchError, firstValueFrom, of, switchMap, throwError} from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { AgoraService } from '../../services/agora.service';
import { VideoCallPlayerComponent } from '../../components/video-call-player/video-call-player.component';
import { AppointmentCardComponent } from '../../components/appointment-card/appointment-card.component';
import { TranslocoModule } from '@ngneat/transloco';
import { CoachingSessionService } from '../../services/coaching-session.service';
import { CoachingSession } from '../../models/coaching-session.models';
import { NavigationService } from '../../services/navigation.service';
import { RatingModalComponent } from '../../components/rating-modal/rating-modal.component';
import { RouterLink } from '@angular/router';
import { APP_ROUTES } from '../../app.routes.definition';
import LoaderComponent from '../../components/loader/loader.component';
import { LogService } from '../../services/log.service';
import {joinAgoraVideoCall, updateAgoraVideoCallIsMinimized} from "../../store/user/user.actions";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
    selector: 'appointments-page',
    standalone: true,
    imports: [
        AsyncPipe,
        VideoCallPlayerComponent,
        AppointmentCardComponent,
        TranslocoModule,
        CommonModule,
        RatingModalComponent,
        RouterLink,
        LoaderComponent,
        NgIf,
    ],
    templateUrl: './appointments-page.component.html',
})
export default class AppointmentsPageComponent implements OnInit {
    #store = inject(Store<AppState>);
    #coachingSessionService = inject(CoachingSessionService);
    #navigationService = inject(NavigationService);
    #loggerService = inject(LogService);

    appointments: CoachingSession[] = [];
    currentUser$ = this.#store.select(selectUserData);
    userHasBasePlan$ = this.#store.select(selectUserHasBasePlan);

    buyBasePlanPath = APP_ROUTES.BUY_BASE_PLAN();
    messagingPath = APP_ROUTES.MESSAGING();

    loading = true;

    constructor() {
        this.currentUser$
            .pipe(
                takeUntilDestroyed(),
                switchMap((user) => {
                    if (user) {
                        return this.#coachingSessionService.getAllCoachingSessions();
                    }
                    return of(null);
                }),
                catchError((error) => {
                    this.#loggerService.error(
                        'Error getting appointments:',
                        error
                    );
                    return throwError(() => error);
                })
            )
            .subscribe({
                next: (response) => {
                    this.appointments = response ?? [];
                    this.loading = false;
                },
                error: (error) => {
                    this.#loggerService.error('Error in subscription:', error);
                    this.loading = false;
                },
            });
    }

    ngOnInit() {
        this.#navigationService.saveCurrentRoute(true);
    }

    async joinVideoMeeting(meetingId: string) {
        const videoCallIsActive = await firstValueFrom(this.#store.select(selectUserAgoraVideoCallIsActive));
        const videoCallIsJoining = await firstValueFrom(this.#store.select(selectUserAgoraVideoCallIsJoining));
        if (videoCallIsActive || videoCallIsJoining) {
            this.#store.dispatch(updateAgoraVideoCallIsMinimized({isMinimized: false}));
        } else {
            this.#store.dispatch(joinAgoraVideoCall({meetingId: meetingId}));
        }
    }
}
