<div class="w-full h-full fixed inset-0 z-99" [ngClass]="{'hidden': $isMinimized | async}" (click)="animateButtons()">
    <div class="w-full h-full relative">
        <div
            class="header-video-container absolute top-0 inset-x-0 z-50 w-full flex items-center justify-center pb-4 pt-6"
        >
            <div class="flex flex-col items-center justify-center">
                <h1 class="text-lg font-semibold text-white">
                    {{
                        "components.video-call-player.sessionLabel" | transloco
                    }}
                </h1>
                <h2 class="text-sm font-light text-white">
                    {{ callTimeDisplay }}
                </h2>
            </div>
        </div>
        <div #localPlayerContainer class="local-video absolute w-24 h-32"></div>
        <div
            #remotePlayerContainer
            class="remote-video w-full h-full bg-black absolute"
        ></div>
        <div
            #remoteScreenSharePlayerContainer
            class="remote-video w-full bg-black absolute z-10"
        ></div>
        <div
            class="footer-video-container bg-white absolute bottom-0 inset-x-0 z-50 w-full flex flex-col items-center justify-center p-10 rounded-t-3xl transition duration-300"
            [ngClass]="{'opacity-0': !buttonsVisible, 'translate-y-full': !buttonsVisible}"
        >
            <div class="w-full flex items-center justify-center">
                @if (cameras().length > 1) {
                    <div class="mx-6">
                        <button
                            class="footer-video-button bg-white border-0 p-4 rounded-full"
                            [disabled]="$isJoining | async"
                            (click)="onToggleFrontCamera()"
                        >
                            <svg class="w-6 h-6 fill-white">
                                <use
                                    href="../../../assets/svg/svGP.svg#switch-camera"
                                ></use>
                            </svg>
                        </button>
                    </div>
                }
                <div class="mx-6">
                    <button class="footer-video-button bg-white border-0 p-4 rounded-full"
                            (click)="onToggleCamera()" [disabled]="$isJoining | async">
                        <svg class="w-6 h-6 fill-white">
                            @if (cameraEnabled()) {
                                <use href="../../../assets/svg/svGP.svg#camera-on"></use>
                            } @else {
                                <use href="../../../assets/svg/svGP.svg#camera-off"></use>
                            }
                        </svg>
                    </button>
                </div>
                <div class="mx-6">
                    <button class="footer-video-button bg-white border-0 p-4 rounded-full"
                            (click)="onToggleBlur()" [disabled]="$isJoining | async">
                        <svg class="w-6 h-6 fill-white">
                            @if (blurEnabled()) {
                                <use href="../../../assets/svg/svGP.svg#blur-on"></use>
                            } @else {
                                <use href="../../../assets/svg/svGP.svg#blur-off"></use>
                            }
                        </svg>
                    </button>
                </div>
                <div class="mx-6">
                    <button
                        class="footer-video-button bg-white border-0 p-4 rounded-full"
                        [disabled]="$isJoining | async"
                        (click)="onToggleMicrophone()"
                    >
                        <svg class="w-6 h-6 fill-white">
                            @if (microphoneEnabled()) {
                                <use href="../../../assets/svg/svGP.svg#videocall-mic-on"></use>
                            } @else {
                                <use href="../../../assets/svg/svGP.svg#videocall-mic-off"></use>
                            }
                        </svg>
                    </button>
                </div>
            </div>
            <div class="mx-6 mt-3 w-full">
                <button
                    class="w-full flex items-center justify-center py-5 px-4 bg-danger rounded-lg"
                    [disabled]="$isJoining | async"
                    (click)="onLeaveMeeting()"
                >
                    <svg class="size-6 fill-white mt-1">
                        <use
                            href="../../../assets/svg/svGP.svg#phone-down"
                        ></use>
                    </svg>
                    <div class="ms-2">
                        <span class="text-sm uppercase text-white  font-bold">
                            Abbandona
                        </span>
                    </div>
                </button>
            </div>
            <div class="w-full flex items-center justify-center">
                <div class="mx-6 mt-3 w-full">
                    <button
                        class="w-full flex items-center justify-center p-1 rounded-lg"
                        (click)="toggleMinimize()"
                    >
                        <svg class="size-4 fill-white mt-1">
                            <use
                                href="../../../assets/svg/svGP.svg#fullscreen-exit"
                            ></use>
                        </svg>
                        <div class="ms-2">
                            <span class="text-xs uppercase text-white font-bold">
                                Riduci a icona
                            </span>
                        </div>
                    </button>
                </div>
                <div class="mx-6 mt-3 w-full">
                    <button
                        class="w-full flex items-center justify-center p-1 rounded-lg"
                        (click)="toggleScreenShare()"
                        [disabled]="screenShareGuardActive || ($isJoining | async)"
                    >
                        <svg class="size-4 fill-white mt-1">
                            <use
                                href="../../../assets/svg/svGP.svg#share"
                            ></use>
                        </svg>
                        <div class="ms-2">
                            <span class="text-xs uppercase text-white font-bold">
                                @if (screenShareEnabled()) {
                                    Interrompi condivisione
                                } @else {
                                    Condividi schermo
                                }
                            </span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

@if ($isMinimized | async) {
    <div
        class="fixed top-0 left-1/2 mt-6 z-99 -translate-x-1/2"
    >
        <button
            (click)="toggleMinimize()"
            class="bg-white border-0 p-4 rounded-full flex items-center shadow-md"
        >
            <svg class="size-4 fill-black">
                <use href="../../../assets/svg/svGP.svg#fullscreen"></use>
            </svg>
            <div class="ms-2">
                <span class="text-xs uppercase font-bold">
                    Videochiamata
                </span>
            </div>
        </button>
    </div>
}
