import {
    AsyncPipe,
    CommonModule,
    DatePipe,
    NgClass, NgForOf,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from "../../../../services/auth.service";
import { TranslocoModule } from "@ngneat/transloco";
import { AppState } from "../../../../store/app.state";
import { TryTerraService } from "../../../../services/try-terra.service";
import {
    selectUserData,
    selectUserHasBasePlan,
    selectUserIsLoading,
    selectUserTryTerraNativeInitializationError,
    selectUserTryTerraIsGettingUserInfo,
    selectUserTryTerraIsNativeInitializing,
    selectUserTryTerraState
} from "../../../../store/user/user.selectors";
import {
    HealthProviderName,
    TryTerraSupportedHealthProvider,
    TryTerraUser,
    TryTerraUserInfoResponse
} from "../../../../models/try-terra.models";
import LoaderComponent from "../../../../components/loader/loader.component";
import {combineLatest, combineLatestAll, first, from, takeUntil} from "rxjs";
import { LogService } from "../../../../services/log.service";
import { NavigationService } from "../../../../services/navigation.service";
import dayjs from "dayjs";
import {ModalService} from "../../../../services/modal.service";
import {NativeAPIService, TargetPlatform} from "../../../../services/native/native-api.service";
import {InfoModalComponent, InfoModalData} from "../../../../components/info-modal/info-modal.component";
import {
    HealthProviderConnectModalComponent, HealthProviderConnectModalData
} from "../../../../components/health-provider-connect-modal/health-provider-connect-modal.component";
import {fetchTryTerraUserInfo} from "../../../../store/user/user.actions";

@Component({
    selector: 'health-providers-page',
    standalone: true,
    imports: [AsyncPipe, RouterModule, TranslocoModule, NgIf, DatePipe, NgForOf, LoaderComponent, CommonModule],
    templateUrl: './health-providers-page.component.html',
    styleUrl: './health-providers-page.component.css',
})
export default class HealthProvidersPageComponent implements OnInit {
    #store = inject(Store<AppState>);
    #tryTerraService = inject(TryTerraService);
    #navigationService = inject(NavigationService);
    #activatedRoute = inject(ActivatedRoute);
    #logService = inject(LogService);
    #modalService = inject(ModalService);

    user$ = this.#store.select(selectUserData);
    userIsLoading$ = this.#store.select(selectUserIsLoading);
    tryTerraState$ = this.#store.select(selectUserTryTerraState);
    tryTerraIsGettingUserInfo$ = this.#store.select(selectUserTryTerraIsGettingUserInfo);

    providers: TryTerraSupportedHealthProvider[] = Object.values(TryTerraSupportedHealthProvider);
    userInfo: TryTerraUser[] = [];
    userProviders: TryTerraSupportedHealthProvider[] = [];
    HealthProviderName = HealthProviderName;

    auth?: {
        status: 'failure' | 'success';
        resource: TryTerraSupportedHealthProvider;
    } | null;

    constructor() {
        const queryParams = this.#activatedRoute.snapshot.queryParams;
        if (queryParams['auth-status'] && queryParams['resource']) {
            this.auth = {
                status: queryParams['auth-status'],
                resource: queryParams['resource'],
            };
            if (this.auth.status === 'success') {
                const startDate = dayjs().add(-7, 'day').startOf('day').toDate();
                const endDate = dayjs().toDate();
                const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
                this.#tryTerraService.triggerWebhooks({
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                    timeZone: timeZone,
                    provider: queryParams['resource'],
                }).subscribe({
                    next: () => {
                        this.#logService.log('TryTerra webhook triggered successfully');
                    },
                    error: err => {
                        this.#logService.error(err);
                    }
                })
            }
        }

        this.#store.dispatch(fetchTryTerraUserInfo({initializeNative: false}));

        combineLatest([
            this.user$.pipe(first(value => value != null)),
            this.tryTerraState$
        ]).pipe(takeUntilDestroyed()).subscribe({
            next: ([user, tryTerraState]) => {
                if (user != null) {
                    if (tryTerraState.userInfoError == null && !tryTerraState.isGettingUserInfo && tryTerraState.userInfo) {
                        this.userInfo = tryTerraState.userInfo;
                        this.userProviders = [...new Set(this.userInfo.map(info => info.provider))] as TryTerraSupportedHealthProvider[];
                        this.#logService.log(this.userInfo);
                    } else if (tryTerraState.userInfoError != null && !tryTerraState.isGettingUserInfo) {
                        this.#logService.error(tryTerraState.userInfoError);
                    }
                }
            },
            error: error => {
                this.#logService.error(error);
            }
        });
    }

    ngOnInit() {
        this.#navigationService.saveCurrentRoute();
    }

    openModalConnect(provider: TryTerraSupportedHealthProvider, isConnecting: boolean) {
        this.#modalService.open<
            HealthProviderConnectModalData,
            HealthProviderConnectModalComponent
        >(HealthProviderConnectModalComponent, {
            data: {
                provider: provider,
                isConnecting: isConnecting
            },
        });
    }
}
