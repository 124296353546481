import {AppState} from '../app.state';
import {createSelector} from '@ngrx/store';
import {ProductFeature} from '../../models/product.model';
import {SupportedBillingCountry} from "../../models/users.models";
import {TryTerraSupportedHealthProvider} from "../../models/try-terra.models";

export const selectUserState = (state: AppState) => state.user;

export const selectUserData = createSelector(
    selectUserState,
    (state) => state.data
);

export const selectUserError = createSelector(
    selectUserState,
    (state) => state.error
);
export const selectUserIsLoading = createSelector(
    selectUserState,
    (state) => state.isLoading
);

export const selectUserOpenOrders = createSelector(
    selectUserData,
    (user) => user?.openOrders ?? []
);

export const selectUserBillingInfoIsValid = createSelector(
    selectUserData,
    (user) => user?.fiscalCode != null && user?.fiscalCode.trim() !== '' &&
        user?.billingCity != null && user?.billingCity.trim() !== '' &&
        user?.billingStreet != null && user?.billingStreet.trim() !== '' &&
        user?.billingPostalCode != null && user?.billingPostalCode.trim() !== '' &&
        (user?.billingCountry === SupportedBillingCountry.Altro ||
            (user?.billingCountry != null && user?.billingCountry.trim() !== '' &&
                user?.billingProvince != null && user?.billingProvince.trim() !== ''))
);

export const selectUserHasPendingOrders = createSelector(
    selectUserOpenOrders,
    (orders) => orders.length > 0
);

export const selectUserFirstPendingOrder = createSelector(
    selectUserOpenOrders,
    (orders) => (orders.length > 0 ? orders[0] : null)
);

export const selectUserPendingOrder = (id: string) =>
    createSelector(selectUserOpenOrders, (orders) =>
        orders.find((o) => o.id === id)
    );

export const selectUserActivatedOrders = createSelector(
    selectUserData,
    (user) => user?.activatedOrders ?? []
);

export const selectUserFeatures = createSelector(
    selectUserData,
    (user) => user?.enabledFeatures
);

export const selectUserHasBasePlan = createSelector(
    selectUserActivatedOrders,
    (activatedOrders) =>
        activatedOrders.some(
            (order) =>
                order.subscriptions != null &&
                order.subscriptions.length > 0 &&
                order.orderItems.some((item) => item.product.type === 'Base')
        ) ?? false
);

export const selectUserHasFeature = (feature: ProductFeature) =>
    createSelector(
        selectUserFeatures,
        (enabledFeatures) => enabledFeatures?.includes(feature) ?? false
    );

export const selectUserSubscriptions = createSelector(
    selectUserActivatedOrders,
    (activatedOrders) =>
        activatedOrders?.filter(
            (order) =>
                order.subscriptions != null && order.subscriptions.length > 0
        ) ?? []
);

export const selectUserBaseSubscription = createSelector(
    selectUserActivatedOrders,
    (activatedOrders) =>
        activatedOrders.find(
            (order) =>
                order.subscriptions != null &&
                order.subscriptions.length > 0 &&
                order.orderItems.some((item) => item.product.type === 'Base')
        )
);

export const selectUserOtherSubscriptions = createSelector(
    selectUserActivatedOrders,
    (activatedOrders) =>
        activatedOrders?.filter(
            (order) =>
                order.subscriptions != null &&
                order.subscriptions.length > 0 &&
                !order.orderItems.some((item) => item.product.type === 'Add-on')
        ) ?? []
);

export const selectUserSubscriptionByProductCode = (productCode: string) =>
    createSelector(
        selectUserSubscriptions,
        (subscriptions) =>
            subscriptions?.find((order) =>
                order.orderItems.some(
                    (item) => item.product.productCode === productCode
                )
            ) ?? null
    );

export const selectUserHasLoginProvider = createSelector(
    selectUserData,
    (user) => user?.loginProvider != null
);

export const selectUserAgoraState = createSelector(
    selectUserState,
    (user) => user?.agora
);

export const selectUserAgoraChatState = createSelector(
    selectUserAgoraState,
    (agoraState) => agoraState?.chat
);

export const selectUserAgoraChatIsLogging = createSelector(
    selectUserAgoraChatState,
    (agoraChatState) => agoraChatState?.isLogging
);

export const selectUserAgoraChatIsLogged = createSelector(
    selectUserAgoraChatState,
    (agoraChatState) => agoraChatState?.isLogged
);

export const selectUserAgoraChatError = createSelector(
    selectUserAgoraChatState,
    (agoraChatState) => agoraChatState?.loginError
);

export const selectUserAgoraListConversationIsLoading = createSelector(
    selectUserAgoraChatState,
    (agoraChatState) => agoraChatState?.conversationListIsLoading
);

export const selectUserAgoraConversationList = createSelector(
    selectUserAgoraChatState,
    (agoraChatState) => agoraChatState?.conversationList
);

export const selectUserAgoraListConversationError = createSelector(
    selectUserAgoraChatState,
    (agoraChatState) => agoraChatState?.conversationListError
);

export const selectUserHasUnreadMessages = createSelector(
    selectUserAgoraConversationList,
    (agoraConversationList) => agoraConversationList?.some(conversation => (conversation.unreadNum ?? 0) > 0)
);

export const selectUserAgoraVideoCallState = createSelector(
    selectUserAgoraState,
    (agoraState) => agoraState?.videoCall
);

export const selectUserAgoraVideoCallIsActive = createSelector(
    selectUserAgoraVideoCallState,
    (agoraVideoCallState) => agoraVideoCallState?.isActive
);

export const selectUserAgoraVideoCallIsJoining = createSelector(
    selectUserAgoraVideoCallState,
    (agoraVideoCallState) => agoraVideoCallState?.isJoining
);

export const selectUserAgoraVideoCallIsMinimized = createSelector(
    selectUserAgoraVideoCallState,
    (agoraVideoCallState) => agoraVideoCallState?.isMinimized
);

export const selectUserTryTerraState = createSelector(
    selectUserState,
    (user) => user?.tryTerra
);

export const selectUserTryTerraUserInfo = createSelector(
    selectUserTryTerraState,
    (healthDataState) => healthDataState?.userInfo
);

export const selectUserTryTerraIsGettingUserInfo = createSelector(
    selectUserTryTerraState,
    (healthDataState) => healthDataState?.isGettingUserInfo
);

export const selectUserTryTerraUserInfoError = createSelector(
    selectUserTryTerraState,
    (healthDataState) => healthDataState?.userInfoError
);

export const selectUserTryTerraNativeInitialized = createSelector(
    selectUserTryTerraState,
    (healthDataState) => healthDataState?.nativeInitialized
);

export const selectUserTryTerraIsNativeInitializing = createSelector(
    selectUserTryTerraState,
    (healthDataState) => healthDataState?.isNativeInitializing
);

export const selectUserTryTerraNativeInitializationError = createSelector(
    selectUserTryTerraState,
    (healthDataState) => healthDataState?.nativeInitializationError
);

export const selectUserTryTerraHasProviders = createSelector(
    selectUserTryTerraUserInfo,
    (userInfo) => (userInfo?.length ?? 0) > 0);

export const selectUserTryTerraHasNativeAppleConnection = createSelector(
    selectUserTryTerraUserInfo,
    (userInfo) => userInfo?.some((user) =>
        user.provider === TryTerraSupportedHealthProvider.APPLE) ?? false
);

export const selectUserTryTerraHasNativeHealthConnectConnection = createSelector(
    selectUserTryTerraUserInfo,
    (userInfo) => userInfo?.some((user) =>
        user.provider === TryTerraSupportedHealthProvider.HEALTH_CONNECT) ?? false
);

export const selectUserFreeTrialEndDate = createSelector(
    selectUserData,
    (user) => user?.freeTrialEndDate
);

export const selectUserGoals = createSelector(
    selectUserData,
    (user) => user?.goals
);
