import {Component, inject, OnInit, ViewContainerRef} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Router, RouterOutlet} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from './store/app.state';
import {
    fetchUser, initializeTryTerra, initializeTryTerraUserInfoFailure, initializeTryTerraUserInfoSuccess,
    loginAgoraChat,
    updateAgoraConversationList
} from './store/user/user.actions';
import {environment} from '../environments/environment';
import {TranslocoService} from '@ngneat/transloco';
import dayjs from 'dayjs';
import it from 'dayjs/locale/it';
import {selectUserData, selectUserFirstPendingOrder,} from "./store/user/user.selectors";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {first, interval, Subscription} from "rxjs";
import {APP_ROUTES} from "./app.routes.definition";
import {CheckForUpdateService} from "./services/check-for-update.service";
import {ProductFeature} from "./models/product.model";
import {FirebaseMessagingNativeService} from "./services/native/firebase-messaging-native.service";
import {LogService} from "./services/log.service";
import {NotificationService} from "./services/notification.service";
import {PayloadChat} from "./models/chat.models";
import {AuthService} from "./services/auth.service";
import {TryTerraService} from "./services/try-terra.service";
import {HealthNativeService} from "./services/native/health-native.service";
import {HealthDataService} from "./services/health-data.service";
import {NativeAPIService, TargetPlatform} from "./services/native/native-api.service";
import {
    AppTrackingTransparencyNativeService,
    TrackingStatus
} from "./services/native/app-tracking-transparency-native.service";
import * as Sentry from "@sentry/angular";
import {PackageInfoNativeService} from "./services/native/package-info-native.service";
import {Connection, TryTerraNativeService} from "./services/native/try-terra-native.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [CommonModule, RouterOutlet],
})
export class AppComponent implements OnInit {
    // DO NOT REMOVE! injected here to listen for updates
    #checkForUpdatesService = inject(CheckForUpdateService);
    // DO NOT REMOVE! injected here to listen for firebase push notifications from native side
    #firebaseMessagingNativeService = inject(FirebaseMessagingNativeService);
    #appTrackingTransparencyNativeService = inject(AppTrackingTransparencyNativeService);

    #store = inject(Store<AppState>);
    #router = inject(Router);
    #translateService = inject(TranslocoService);
    #logService = inject(LogService);
    #notificationService = inject(NotificationService);
    #authService = inject(AuthService);
    #tryTerraService = inject(TryTerraService);
    #tryTerraNativeService = inject(TryTerraNativeService);
    #healthNativeService = inject(HealthNativeService);
    #healthDataService = inject(HealthDataService);
    #nativeAPIService = inject(NativeAPIService);
    #packageInfoNativeService = inject(PackageInfoNativeService);

    // public property used by services
    viewRef = inject(ViewContainerRef);

    accessTokenLabel = environment.accessTokenKey;
    currentLanguage = this.#translateService.getDefaultLang();

    #userData$ = this.#store.select(selectUserData);
    #pendingOrder$ = this.#store.select(selectUserFirstPendingOrder);

    title = 'Be Our Best';
    #interval$: Subscription | null = null;

    constructor() {
        if (environment.sentryEnabled) {
            if (this.#packageInfoNativeService.isAvailable()) {
                this.#packageInfoNativeService
                    .fromPlatform()
                    .then((packageInfo) => {
                        const appNativeVersion = packageInfo.version + '+' + packageInfo.buildNumber;
                        Sentry.setExtra('Native App Version', appNativeVersion);
                    });
            }
        }

        dayjs.locale(it);

        if (environment.googleAnalyticsTrackingId != null && environment.googleAnalyticsTrackingId.trim() !== '') {
            this.loadGoogleAnalytics(environment.googleAnalyticsTrackingId);
        }

        if (this.#authService.isAuthenticated()) {
            this.#store.dispatch(fetchUser());
        }

        this.#pendingOrder$.pipe(takeUntilDestroyed(), first(value => value != null)).subscribe(pendingOrder => {
            if (!this.#router.url.startsWith(APP_ROUTES.PAYMENT_REDIRECT())) {
                this.#router.navigate([APP_ROUTES.PENDING_ORDER(false, pendingOrder!.id)]);
            }
        });

        this.#userData$.pipe(takeUntilDestroyed(), first(value => value != null)).subscribe(user => {
            if (user && this.#nativeAPIService.isAvailable()) {
                // TODO: remove when Try Terra fixes health connect for Android
                this.#nativeAPIService.defaultTargetPlatform().then(platform => {
                    if (platform === TargetPlatform.android) {
                        this.#tryTerraNativeService.isConnectionTypeImplemented({connection: Connection.healthConnect}).then(implemented => {
                            this.#logService.log(`TryTerra Connection.healthConnect connection type is implemented: ${implemented}`);
                            if (!implemented) {
                                this.#logService.log(`TryTerra Connection.healthConnect not implemented on Android. Fallback to temp health data service for health data upload.`);
                                this.#healthDataService.getAndSendTempAndroidHealthData(user);
                            }
                        });
                    }
                });
            }
            if (user && user.enabledFeatures.includes(ProductFeature.CHAT)) {
                this.#store.dispatch(loginAgoraChat({userId: user.sfAccountId}));
            }
            this.#tryTerraService.getUserInfo().subscribe({
                next: value => {
                    if (value.status === 'success' && value.users && value.users.length > 0) {
                        this.#store.dispatch(initializeTryTerraUserInfoSuccess({userInfo: value.users}));

                        let latestWebhookUpdate = 0;
                        for (const tryTerraUser of value.users) {
                            // milliseconds
                            const userLastWebhookUpdate = (new Date(tryTerraUser.last_webhook_update)).getTime();
                            if (!isNaN(userLastWebhookUpdate) && userLastWebhookUpdate > latestWebhookUpdate) {
                                latestWebhookUpdate = userLastWebhookUpdate;
                            }
                        }
                        if (latestWebhookUpdate > 0) {
                            const daysBeforeNow = Math.min(7, Math.abs(Date.now() - latestWebhookUpdate) / (60 * 60 * 24 * 1000));
                            this.#store.dispatch(initializeTryTerra({
                                triggerAllDataWebhook: true,
                                daysBeforeNow: daysBeforeNow
                            }));
                        } else {
                            this.#store.dispatch(initializeTryTerra({
                                triggerAllDataWebhook: false
                            }));
                        }
                    } else {
                        this.#store.dispatch(initializeTryTerraUserInfoFailure({error: new Error('No TryTerra User found')}));
                    }
                },
                error: err => {
                    this.#logService.error(err);
                    this.#store.dispatch(initializeTryTerraUserInfoFailure({error: err}));
                }
            })
        });

        this.#userData$.pipe(takeUntilDestroyed()).subscribe(user => {
            if (user && user.enabledFeatures.includes(ProductFeature.CHAT)) {
                if (this.#interval$ == null) {
                    // Agora Chat has 5 GET Conversation List API limit per minute per user
                    // trying to get agora conversation info every ~20 seconds
                    this.#interval$ = interval(20000).subscribe(
                        () => this.#store.dispatch(updateAgoraConversationList())
                    );
                }
            } else {
                this.#interval$?.unsubscribe();
                this.#interval$ = null;
            }
        });

        if (this.#firebaseMessagingNativeService.isAvailable()) {
            this.#firebaseMessagingNativeService.onMessage$.pipe(takeUntilDestroyed()).subscribe({
                next: remoteMessage => {
                    this.#logService.log(remoteMessage);

                    const shouldOpenNotificationPopup = this.#notificationService.shouldOpenNotificationPopup(remoteMessage);
                    if (shouldOpenNotificationPopup) {
                        this.#notificationService.open({
                            data: {
                                title: remoteMessage.notification?.title ?? '',
                                subTitle: remoteMessage.notification?.body ?? '',
                                imageUrl: remoteMessage.data['profileImageUrl'],
                                remoteMessage: remoteMessage
                            },
                            actions: {
                                click: (remoteMessage) => {
                                    this.#logService.log('click push notification', remoteMessage);
                                    if (remoteMessage) {
                                        if (this.#notificationService.isAgoraSfChatNotification(remoteMessage)) {
                                            let payloadForChat: PayloadChat = {
                                                userId: remoteMessage.data['contactId'],
                                                userName: `${remoteMessage.data['firstName']}`,
                                                userAvatar: remoteMessage.data['profileImageUrl'],
                                            };

                                            this.#router.navigate([APP_ROUTES.MESSAGING_ID(false, remoteMessage.data['contactId'])], {
                                                state: {
                                                    data: payloadForChat
                                                }
                                            });
                                            return true;
                                        } else if (this.#notificationService.isFreeChatNotification(remoteMessage)) {
                                            let payloadForChat: PayloadChat = {
                                                userId: remoteMessage.data['contactId'],
                                                userName: `${remoteMessage.data['firstName']}`,
                                                userAvatar: remoteMessage.data['profileImageUrl'],
                                            };

                                            this.#router.navigate([APP_ROUTES.FREE_MESSAGING(false, remoteMessage.data['contactId'])], {
                                                state: {
                                                    data: payloadForChat
                                                }
                                            });
                                            return true;
                                        }
                                    }
                                    return false;
                                }
                            }
                        });
                    }
                },
                error: err => {
                    this.#logService.error(err.message);
                }
            });

            this.#firebaseMessagingNativeService.onMessageOpenedApp$.pipe(takeUntilDestroyed()).subscribe({
                next: remoteMessage => {
                    this.#logService.log('click on native push notification', remoteMessage);
                    if (remoteMessage) {
                        if (this.#notificationService.isAgoraSfChatNotification(remoteMessage)) {
                            let payloadForChat: PayloadChat = {
                                userId: remoteMessage.data['contactId'],
                                userName: `${remoteMessage.data['firstName']}`,
                                userAvatar: remoteMessage.data['profileImageUrl'],
                            };

                            this.#router.navigate([APP_ROUTES.MESSAGING_ID(false, remoteMessage.data['contactId'])], {
                                state: {
                                    data: payloadForChat
                                }
                            });
                        } else if (this.#notificationService.isFreeChatNotification(remoteMessage)) {
                            let payloadForChat: PayloadChat = {
                                userId: remoteMessage.data['contactId'],
                                userName: `${remoteMessage.data['firstName']}`,
                                userAvatar: remoteMessage.data['profileImageUrl'],
                            };

                            this.#router.navigate([APP_ROUTES.FREE_MESSAGING(false, remoteMessage.data['contactId'])], {
                                state: {
                                    data: payloadForChat
                                }
                            });
                        }
                    }
                },
                error: err => {
                    this.#logService.error(err.message);
                }
            });
        }
    }

    ngOnInit() {
        // Internationalization
        this.#translateService.setActiveLang(this.currentLanguage);
    }

    private async loadGoogleAnalytics(trackingID: string) {
        try {
            const isAvailable = await this.#appTrackingTransparencyNativeService.isAvailable();
            if (isAvailable) {
                let trackingStatus = await this.#appTrackingTransparencyNativeService.trackingAuthorizationStatus();
                if (trackingStatus === TrackingStatus.notDetermined) {
                    trackingStatus = await this.#appTrackingTransparencyNativeService.requestTrackingAuthorization();
                }
                if (trackingStatus !== TrackingStatus.authorized) {
                    this.#logService.debug('App Tracking Transparency status not authorized, so block the GA script', trackingStatus);
                    return;
                }
                this.#logService.debug('App Tracking Transparency status authorized, so load the GA script');
            } else {
                this.#logService.debug('AppTrackingTransparencyNativeService not available, so load the GA script');
            }
        } catch (error) {
            this.#logService.debug('AppTrackingTransparencyNativeService not available, so load the GA script', error);
        }

        let gaScript = document.createElement('script');
        gaScript.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${trackingID}');`;

        let gaScript2 = document.createElement('noscript');
        gaScript2.innerText = `<iframe src="https://www.googletagmanager.com/ns.html?id=${trackingID}" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`;

        document.head.prepend(gaScript);
        document.body.prepend(gaScript2);
    }
}
