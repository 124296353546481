import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {catchError, Observable, of, throwError} from 'rxjs';
import {User, UserUpdateBillingInfoData, UserUpdateInfoData} from '../models/users.models';
import {environment} from '../../environments/environment';
import {TwoFactorInitResponse} from '../models/responses/two-factor-init-response.model';
import {
    AuthResponse,
    TwoFactorAuthResponse,
} from '../models/responses/auth-response.model';
import {RegistrationData} from '../models/registration-data.model';
import {RegistrationWithProviderData} from '../models/registration-with-provider-data.model';
import {map} from "rxjs/operators";
import {SupportRequest} from '../pages/settings-page/pages/support-page/support-page.component';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    #http = inject(HttpClient);

    #account = '/account';
    #billingInfo = `${this.#account}/billing-info`;
    #activate = '/activate';
    #anonymise = '/anonymise';
    #apiUrl = environment.apiUrl;
    #changeEmail = '/change-email';
    #changePassword = '/change-password';
    #disable = '/disable';
    #enableFinish = '/enable-finish';
    #enableInit = '/enable-init';
    #finish = '/finish';
    #init = '/init';
    isRegistered = false;
    #refreshRecoveryCode = '/refresh-recovery-code';
    #register = '/register';
    #registerWithProvider = '/register-with-provider';
    #resetPassword = '/reset-password';
    #resend = '/resend';
    #twoFactor = '/2fa';
    #avatar = '/avatar';
    #checkFeelingSurvey = '/check-how-you-feel-today';
    #support = '/support';
    #logout = '/logout';

    // Register the user account sending a link for activation
    registerAccount(registrationData: RegistrationData) {
        return this.#http.post<string>(`${this.#apiUrl}${this.#register}`, registrationData);
    }

    // Register the user account using Google sending access token & survey completed
    registerAccountWithProvider(
        userRegistrationData: RegistrationWithProviderData
    ) {
        return this.#http.post<User>(
            `${this.#apiUrl}${this.#registerWithProvider}`,
            userRegistrationData
        );
    }

    // Activate the user account after link click
    activateAccount(activationKey: string) {
        return this.#http.post<User>(
            `${this.#apiUrl}${this.#account}${this.#activate}`,
            {activationKey}
        );
    }

    // Anonymize an account
    anonymizeUser(password: string | null) {
        return this.#http.post(
            `${this.#apiUrl}${this.#account}${this.#anonymise}`,
            {
                password: password
            }
        );
    }

    // Get the current user
    getAccount(): Observable<User> {
        return this.#http.get<User>(`${this.#apiUrl}${this.#account}`);
    }

    // Update the current user information
    updateUser(userUpdateInfoData: UserUpdateInfoData) {
        return this.#http.post<void>(`${this.#apiUrl}${this.#account}`, userUpdateInfoData);
    }

    // Update the current user billing information
    updateUserBillingInfo(userBillingInfo: UserUpdateBillingInfoData) {
        return this.#http.post<void>(`${this.#apiUrl}${this.#billingInfo}`, userBillingInfo);
    }

    // Start change user email process
    verifyEmailChange(email: string) {
        return this.#http.post(
            `${this.#apiUrl}${this.#account}${this.#changeEmail}${this.#init}`,
            {
                newEmail: email,
            }
        );
    }

    // Ask for new otp
    resendEmailChangeOtp() {
        return this.#http.post(
            `${this.#apiUrl}${this.#account}${this.#changeEmail}${
                this.#resend
            }`,
            {}
        );
    }

    // Verify OTP and finish the change user email process
    verifyEmailChangeWithCode(otp: string) {
        return this.#http.post(
            `${this.#apiUrl}${this.#account}${this.#changeEmail}${
                this.#finish
            }`,
            {
                otp: otp,
            }
        );
    }

    // Change current user password
    changeUserPassword(currentPassword: string, newPassword: string) {
        return this.#http.post(
            `${this.#apiUrl}${this.#account}${this.#changePassword}`,
            {
                currentPassword: currentPassword,
                newPassword: newPassword,
            }
        );
    }

    // Initialize the reset password process with userEmail
    sendEmailForReset(email: string) {
        return this.#http.post(
            `${this.#apiUrl}${this.#account}${this.#resetPassword}${
                this.#init
            }`,
            {
                userEmail: email,
            }
        );
    }

    // Finalize the Reset of the current user pasword
    confirmResetPassword(resetKey: string, newPassword: string) {
        return this.#http.post(
            `${this.#apiUrl}${this.#account}${this.#resetPassword}${
                this.#finish
            }`,
            {
                resetKey: resetKey,
                newPassword: newPassword,
            }
        );
    }

    // Initialize the 2fa process
    initiateTwoFactorAuthentication(): Observable<TwoFactorInitResponse> {
        return this.#http.post<TwoFactorInitResponse>(
            `${this.#apiUrl}${this.#account}${this.#twoFactor}${
                this.#enableInit
            }`,
            {}
        );
    }

    // Enable the 2fa for the user
    enableTwoFactorAuthentication(
        otp: string
    ): Observable<TwoFactorAuthResponse> {
        return this.#http.post<TwoFactorAuthResponse>(
            `${this.#apiUrl}${this.#account}${this.#twoFactor}${
                this.#enableFinish
            }`,
            {token: otp}
        );
    }

    // Disable the 2fa authentication for the user
    disableTwoFactorAuthentication(password: string): Observable<AuthResponse> {
        return this.#http.post<AuthResponse>(
            `${this.#apiUrl}${this.#account}${this.#twoFactor}${this.#disable}`,
            {
                password: password
            }
        );
    }

    // Refresh the list of recovery codes (6 codes for now)
    refreshRecoveryCode(): Observable<string[]> {
        return this.#http.post<string[]>(
            `${this.#apiUrl}${this.#account}${this.#twoFactor}${
                this.#refreshRecoveryCode
            }`,
            {}
        );
    }

    updateAvatar(avatar: Blob) {
        const formData = new FormData();
        formData.set('data', avatar);
        return this.#http.post(
            `${this.#apiUrl}${this.#account}${this.#avatar}`,
            formData
        );
    }

    // Check if user has already sent the "How you feel today" survey for today
    hasTodayFeelingSurvey() {
        return this.#http.get(`${this.#apiUrl}${this.#account}${this.#checkFeelingSurvey}`, {observe: 'response'}).pipe(
            map((response) => {
                return !!(response.body ?? false);
            }),
        );
    }

    // Send support form to the backend
    sendSupportRequest(supportRequest: SupportRequest) {
        return this.#http.post(
            `${this.#apiUrl}${this.#account}${this.#support}`,
            supportRequest
        );
    }

    logout() {
        return this.#http.get(
            `${this.#apiUrl}${this.#logout}`
        );
    }
}
