import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    ViewChild,
    inject,
    Renderer2,
    OnDestroy, DestroyRef, HostListener,
} from '@angular/core';
import AgoraRTC, {AgoraRTCErrorCode, IAgoraRTCRemoteUser} from 'agora-rtc-sdk-ng';
import {TranslocoModule} from '@ngneat/transloco';
import {LogService} from '../../services/log.service';
import {AsyncPipe, NgClass} from "@angular/common";
import {AppState} from "../../store/app.state";
import {Store} from "@ngrx/store";
import {
    selectUserAgoraVideoCallIsActive, selectUserAgoraVideoCallIsJoining,
    selectUserAgoraVideoCallIsMinimized,
} from "../../store/user/user.selectors";
import {leaveAgoraVideoCall, toggleAgoraVideoCallIsMinimized} from "../../store/user/user.actions";
import {VideoCallService} from "../../services/video-call.service";
import {debounceTime, first, fromEvent, throttleTime} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import ErrorModalComponent, {ErrorModalData} from "../error-modal/error-modal.component";
import {ModalService} from "../../services/modal.service";

export interface LeaveEventData {
    meetingId: string;
    time: string;
    elapsedCallTime: number; // in seconds
}

@Component({
    selector: 'video-call-player',
    standalone: true,
    imports: [TranslocoModule, NgClass, AsyncPipe],
    templateUrl: './video-call-player.component.html',
    styleUrl: './video-call-player.component.css',
})
export class VideoCallPlayerComponent implements AfterViewInit, OnDestroy {
    @ViewChild('remotePlayerContainer') remotePlayerContainer: ElementRef =
        {} as ElementRef;
    @ViewChild('remoteScreenSharePlayerContainer') remoteScreenSharePlayerContainer: ElementRef =
        {} as ElementRef;
    @ViewChild('localPlayerContainer') localPlayerContainer: ElementRef =
        {} as ElementRef;

    @Input() meetingId: string = '';

    #videoCallService = inject(VideoCallService);
    #domRenderer = inject(Renderer2);
    #logService = inject(LogService);
    #store = inject(Store<AppState>);
    #destroyRef = inject(DestroyRef);
    #modalService = inject(ModalService);
    #elementRef = inject(ElementRef);

    $isActive = this.#store.select(selectUserAgoraVideoCallIsActive);
    $isJoining = this.#store.select(selectUserAgoraVideoCallIsJoining);
    $isMinimized = this.#store.select(selectUserAgoraVideoCallIsMinimized);

    callStartTime: number = 0;
    callTimerId: ReturnType<typeof setTimeout> | null = null;
    elapsedCallTime: number = 0;
    callTimeDisplay: string = '00:00 min';
    screenShareGuardActive: boolean = false;
    buttonsVisible: boolean = true;
    buttonsDisplayNone: boolean = false;
    #timeoutId1: ReturnType<typeof setTimeout> | null = null;

    ngAfterViewInit() {
        this.startTimer();

        this.#videoCallService.$onRemoteUserPublishedSubject.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe({
            next: ({user, mediaType}) => {
                let remoteUser = this.getOrCreateRemoteUser(user);

                const divContainer = this.#domRenderer.createElement('div');
                this.#domRenderer.setProperty(
                    divContainer,
                    'id',
                    user.uid.toString()
                );

                if (mediaType === 'video') {
                    remoteUser.remoteVideo = user.videoTrack;
                    remoteUser.remoteAudio = user.audioTrack;

                    const div = this.createVideoElement(user);

                    if (!user.uid.toString().endsWith('_screen')) {
                        this.remotePlayerContainer.nativeElement.innerHTML = '';
                    }

                    this.#domRenderer.appendChild(divContainer, div);
                    this.#domRenderer.appendChild(
                        user.uid.toString().endsWith('_screen') ?
                            this.remoteScreenSharePlayerContainer.nativeElement :
                            this.remotePlayerContainer.nativeElement,
                        divContainer
                    );

                    remoteUser.remoteVideo?.play(div, {
                        fit: 'contain',
                    });
                }

                if (mediaType === 'audio') {
                    remoteUser.remoteAudio = user.audioTrack;
                    remoteUser.remoteAudio?.play();
                }

                if (!user.uid.toString().endsWith('_screen')) {
                    this.animateButtons();
                }
            }
        });

        this.#videoCallService.$onRemoteUserUnpublishedSubject.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe({
            next: ({user, mediaType}) => {
                if (user.uid.toString().endsWith('_screen')) {
                    this.remoteScreenSharePlayerContainer.nativeElement.innerHTML = '';
                } else {
                    this.showButtons();
                }
            }
        });

        this.setupLocalVideo();
    }

    @HostListener('document:mousemove') onMouseMove() {
        this.animateButtons();
    }

    showButtons() {
        this.buttonsVisible = true;
        this.buttonsDisplayNone = false;
    }

    animateButtons() {
        this.showButtons();
        if (this.#timeoutId1) {
            clearTimeout(this.#timeoutId1);
        }
        this.#timeoutId1 = setTimeout(() => {
            this.buttonsVisible = false;
        }, 3500);
    }

    private async setupLocalVideo() {
        this.$isActive.pipe(first(v => v)).subscribe({
            next: async (isActive) => {
                console.log('Setting up local video');
                if (this.localPlayerContainer && this.localPlayerContainer.nativeElement) {
                    this.#videoCallService.videoCallTracks?.localVideo?.play(this.localPlayerContainer.nativeElement);
                }
            }
        });
    }

    private getOrCreateRemoteUser(user: IAgoraRTCRemoteUser) {
        let remoteUser =
            this.#videoCallService.videoCallTracks?.remoteParticipants[user.uid.toString()];
        if (!remoteUser) {
            remoteUser = {
                remoteVideo: user.videoTrack,
                remoteAudio: user.audioTrack,
            };
            this.#videoCallService.videoCallTracks!.remoteParticipants[user.uid.toString()] =
                remoteUser;
        }
        return remoteUser;
    }

    private createVideoElement(user: IAgoraRTCRemoteUser) {
        const existingElement =
            this.remotePlayerContainer.nativeElement.querySelector(
                `#video-${user.uid.toString()}`
            );

        if (existingElement) {
            return existingElement;
        }

        const div = this.#domRenderer.createElement('div');
        this.#domRenderer.setProperty(
            div,
            'id',
            'video-' + user.uid.toString()
        );
        this.#domRenderer.setStyle(div, 'width', '100%');
        this.#domRenderer.setStyle(div, 'height', '100vh');

        return div;
    }

    onLeaveMeeting() {
        this.#store.dispatch(leaveAgoraVideoCall({
            meetingId: this.meetingId,
            callTimeDisplay: this.callTimeDisplay,
            elapsedCallTime: this.elapsedCallTime,
        }));
    }

    onToggleFrontCamera() {
        this.#videoCallService.toggleFrontCamera()?.then(() => {
            this.#logService.log(`Switched camera for local video successfully. Front Camera: ${this.#videoCallService.frontCamera}`);
        }).catch(reason => {
            this.#logService.error(reason);
        });
    }

    onToggleMicrophone() {
        this.#videoCallService.toggleMicrophone()?.then(() => {
            this.#logService.log(`Updated microphone state for local audio successfully. Enabled: ${this.#videoCallService.microphoneEnabled}`);
        }).catch(reason => {
            this.#logService.error(reason);
        });
    }

    onToggleCamera() {
        this.#videoCallService.toggleCamera()?.then(() => {
            this.#logService.log(`Updated camera state for local video successfully. Enabled: ${this.#videoCallService.cameraEnabled}`);
        }).catch(reason => {
            this.#logService.error(reason);
        });
    }

    onToggleBlur() {
        this.#videoCallService.toggleBlur()?.then(() => {
            this.#logService.log(`Updated blur effect. Enabled: ${this.#videoCallService.blurEnabled}`);
        }).catch(reason => {
            this.#logService.error(reason);
        });
    }

    private startTimer() {
        this.callStartTime = Date.now();
        this.callTimerId = setInterval(() => {
            this.elapsedCallTime = Math.floor(
                (Date.now() - this.callStartTime) / 1000
            );
            this.callTimeDisplay = this.formatTime(this.elapsedCallTime);
        }, 1000);
    }

    stopTimer() {
        if (this.callTimerId) {
            clearInterval(this.callTimerId);
            this.callTimerId = null;
        }
    }

    formatTime(seconds: number): string {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;

        if (hours > 0) {
            // Format as "HH:MM:SS min" if hours > 0
            return `${this.padZero(hours)}:${this.padZero(
                minutes
            )}:${this.padZero(secs)} min`;
        } else {
            // Format as "MM:SS min" if hours = 0
            return `${this.padZero(minutes)}:${this.padZero(secs)} min`;
        }
    }

    padZero(num: number): string {
        return num.toString().padStart(2, '0');
    }

    toggleMinimize() {
        this.#store.dispatch(toggleAgoraVideoCallIsMinimized());
    }

    toggleScreenShare() {
        if (this.screenShareGuardActive) {
            return;
        }
        this.screenShareGuardActive = true;

        if (this.#videoCallService.screenShareEnabled) {
            this.#videoCallService.stopScreenShare()?.then(() => {
                this.#logService.log('Screen sharing stopped');
            }).catch(reason => {
                this.#logService.error(reason);
            }).finally(() => {
                this.screenShareGuardActive = false;
            });
        } else {
            this.#videoCallService.screenShare(this.meetingId)?.then(() => {
                this.#logService.log('Screen sharing started');
            }).catch(reason => {
                const reasonCode = reason.code as AgoraRTCErrorCode;
                this.#logService.error('Failed to start screen sharing', reasonCode, reason);

                // PERMISSION_DENIED is trigger also on user cancel operation!
                // So, we exclude it from the error modal.
                if (reasonCode !== 'PERMISSION_DENIED') {
                    let message = 'Non è stato possibile condividere lo schermo.';
                    if (reasonCode === 'NOT_SUPPORTED') {
                        message = 'Questo dispositivo non supporta la condivisione dello schermo.';
                    }
                    this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                        ErrorModalComponent,
                        {
                            data: {
                                error: reason,
                                message: message
                            },
                        }
                    );
                }
            }).finally(() => {
                this.screenShareGuardActive = false;
            });
        }
    }

    cameras() {
        return this.#videoCallService.cameras;
    }

    cameraEnabled() {
        return this.#videoCallService.cameraEnabled;
    }

    microphoneEnabled() {
        return this.#videoCallService.microphoneEnabled;
    }

    blurEnabled() {
        return this.#videoCallService.blurEnabled;
    }

    screenShareEnabled() {
        return this.#videoCallService.screenShareEnabled;
    }

    ngOnDestroy() {
        this.onLeaveMeeting();
        this.stopTimer();
        if (this.#timeoutId1) {
            clearTimeout(this.#timeoutId1);
        }
    }
}
