import {createReducer, on} from '@ngrx/store';
import {UserState} from './user.state';
import {
    deleteUser,
    deleteUserFailure,
    deleteUserSuccess,
    fetchUser,
    fetchUserFailure,
    fetchUserSuccess,
    initializeTryTerra,
    initializeTryTerraFailure,
    initializeTryTerraSuccess,
    initializeTryTerraUserInfo,
    initializeTryTerraUserInfoFailure,
    initializeTryTerraUserInfoSuccess,
    joinAgoraVideoCall,
    joinAgoraVideoCallFailure,
    joinAgoraVideoCallSuccess,
    leaveAgoraVideoCall,
    login,
    loginAgoraChat,
    loginAgoraChatFailure,
    loginAgoraChatSuccess,
    loginFailure,
    loginOtp,
    loginOtpFailure,
    loginProvider,
    loginRecoveryCode,
    loginSuccess,
    logout,
    logoutAgoraChat,
    setLoading,
    toggleAgoraVideoCallIsMinimized,
    updateAgoraConversationList,
    updateAgoraConversationListFailure,
    updateAgoraConversationListSuccess,
    updateAgoraVideoCallIsMinimized,
    updateUser,
    updateUserAvatar,
    updateUserAvatarFailure,
    updateUserAvatarSuccess,
    updateUserBillingInfo,
    updateUserBillingInfoFailure,
    updateUserBillingInfoSuccess,
    updateUserFailure,
    updateUserSuccess,
} from './user.actions';

const initialState: UserState = {
    data: null,
    error: null,
    isLoading: false,
    tryTerra: {
        userInfo: null,
        isGettingUserInfo: false,
        userInfoError: null,
        initialized: false,
        isInitializing: false,
        initializationError: null,
    },
    agora: {
        chat: {
            isLogging: false,
            isLogged: false,
            loginError: null,
            conversationList: [],
            conversationListIsLoading: false,
            conversationListError: null
        },
        videoCall: {
            isActive: false,
            isJoining: false,
            isMinimized: false,
            meetingId: null,
        }
    }
};

export const userReducer = createReducer(
    initialState,
    on(login, (state) => ({
        ...state,
        error: null,
        isLoading: true,
        tryTerra: {
            ...state.tryTerra,
        },
        agora: {
            ...initialState.agora
        }
    })),
    on(setLoading, (state, {isLoading}) => ({
        ...state,
        error: null,
        isLoading,
    })),
    on(loginProvider, (state) => ({
        ...state,
        error: null,
        isLoading: true,
        tryTerra: {
            ...state.tryTerra,
        },
        agora: {
            ...initialState.agora
        }
    })),
    on(loginOtp, (state) => ({
        ...state,
        error: null,
        isLoading: true,
        tryTerra: {
            ...state.tryTerra,
        },
        agora: {
            ...initialState.agora
        }
    })),
    on(loginRecoveryCode, (state) => ({
        ...state,
        error: null,
        isLoading: true,
        tryTerra: {
            ...state.tryTerra,
        },
        agora: {
            ...initialState.agora
        }
    })),
    on(loginSuccess, (state, {data}) => ({
        ...state,
        data,
        error: null,
        isLoading: false,
    })),
    on(loginFailure, (state, {error}) => ({
        ...state,
        error,
        isLoading: false,
        tryTerra: {
            ...state.tryTerra,
        },
        agora: {
            ...initialState.agora
        }
    })),
    on(loginOtpFailure, (state, {error}) => ({
        ...state,
        error,
        isLoading: false,
        tryTerra: {
            ...state.tryTerra,
        },
        agora: {
            ...initialState.agora
        }
    })),
    on(loginAgoraChat, (state) => ({
        ...state,
        agora: {
            chat: {
                isLogging: true,
                isLogged: false,
                loginError: null,
                conversationList: [],
                conversationListIsLoading: false,
                conversationListError: null
            },
            videoCall: {
                ...state.agora.videoCall
            },
        }
    })),
    on(loginAgoraChatSuccess, (state) => ({
        ...state,
        agora: {
            chat: {
                isLogging: false,
                isLogged: true,
                loginError: null,
                conversationList: [],
                conversationListIsLoading: false,
                conversationListError: null
            },
            videoCall: {
                ...state.agora.videoCall
            },
        }
    })),
    on(loginAgoraChatFailure, (state, {error}) => ({
        ...state,
        agora: {
            chat: {
                isLogging: false,
                isLogged: false,
                loginError: error,
                conversationList: [],
                conversationListIsLoading: false,
                conversationListError: null
            },
            videoCall: {
                ...state.agora.videoCall
            },
        }
    })),
    on(logoutAgoraChat, (state) => ({
        ...state,
        agora: {
            chat: {
                ...initialState.agora.chat,
                loginError: state.agora.chat.loginError,
            },
            videoCall: {
                ...state.agora.videoCall
            },
        }
    })),
    on(fetchUser, (state) => ({
        ...state,
        error: null,
        isLoading: true,
    })),
    on(fetchUserSuccess, (state, {data}) => ({
        ...state,
        data,
        error: null,
        isLoading: false,
    })),
    on(fetchUserFailure, (state, {error}) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(logout, () => initialState),
    on(updateUser, (state, {data}) => ({
        ...state,
        error: null,
        isLoading: true,
    })),
    on(updateUserSuccess, (state, {data}) => ({
        ...state,
        data: state.data ? {
            ...state.data,
            ...data
        } : state.data,
        error: null,
        isLoading: false,
    })),
    on(updateUserFailure, (state, {error}) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(updateUserBillingInfo, (state, {data}) => ({
        ...state,
        error: null,
        isLoading: true,
    })),
    on(updateUserBillingInfoSuccess, (state, {data}) => ({
        ...state,
        data: state.data ? {
            ...state.data,
            ...data
        } : state.data,
        error: null,
        isLoading: false,
    })),
    on(updateUserBillingInfoFailure, (state, {error}) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(updateUserAvatar, (state) => ({
        ...state,
        error: null,
        isLoading: true,
    })),
    on(updateUserAvatarSuccess, (state) => ({
        ...state,
        error: null,
        isLoading: false,
    })),
    on(updateUserAvatarFailure, (state, {error}) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(deleteUser, (state) => ({
        ...state,
        error: null,
        isLoading: true,
    })),
    on(deleteUserSuccess, (state) => ({
        ...state,
        error: null,
        isLoading: false,
    })),
    on(deleteUserFailure, (state, {error}) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(initializeTryTerraUserInfo, (state) => ({
        ...state,
        tryTerra: {
            ...state.tryTerra,
            isGettingUserInfo: true,
        }
    })),
    on(initializeTryTerraUserInfoSuccess, (state, {userInfo}) => ({
        ...state,
        tryTerra: {
            ...state.tryTerra,
            userInfo: userInfo,
            isGettingUserInfo: false,
            userInfoError: null,
        }
    })),
    on(initializeTryTerraUserInfoFailure, (state, {error}) => ({
        ...state,
        tryTerra: {
            ...state.tryTerra,
            userInfo: null,
            isGettingUserInfo: false,
            userInfoError: error,
        }
    })),
    on(initializeTryTerra, (state) => ({
        ...state,
        tryTerra: {
            ...state.tryTerra,
            initialized: false,
            isInitializing: true,
            initializationError: null
        }
    })),
    on(initializeTryTerraSuccess, (state) => ({
        ...state,
        tryTerra: {
            ...state.tryTerra,
            initialized: true,
            isInitializing: false,
            initializationError: null
        }
    })),
    on(initializeTryTerraFailure, (state, {error}) => ({
        ...state,
        tryTerra: {
            ...state.tryTerra,
            initialized: false,
            isInitializing: false,
            initializationError: error
        }
    })),
    on(updateAgoraConversationList, (state) => ({
        ...state,
        agora: {
            chat: {
                ...state.agora.chat,
                conversationList: [...state.agora.chat.conversationList],
                conversationListIsLoading: true,
                conversationListError: null
            },
            videoCall: {
                ...state.agora.videoCall
            },
        }
    })),
    on(updateAgoraConversationListSuccess, (state, {conversationList}) => ({
        ...state,
        agora: {
            chat: {
                ...state.agora.chat,
                conversationList: conversationList,
                conversationListIsLoading: false,
                conversationListError: null
            },
            videoCall: {
                ...state.agora.videoCall
            },
        }
    })),
    on(updateAgoraConversationListFailure, (state, {error}) => ({
        ...state,
        agora: {
            chat: {
                ...state.agora.chat,
                conversationList: [],
                conversationListIsLoading: false,
                conversationListError: error
            },
            videoCall: {
                ...state.agora.videoCall
            },
        }
    })),
    on(updateAgoraVideoCallIsMinimized, (state, {isMinimized}) => ({
        ...state,
        agora: {
            chat: {
                ...state.agora.chat,
            },
            videoCall: {
                ...state.agora.videoCall,
                isMinimized: isMinimized
            },
        }
    })),
    on(toggleAgoraVideoCallIsMinimized, (state) => ({
        ...state,
        agora: {
            chat: {
                ...state.agora.chat,
            },
            videoCall: {
                ...state.agora.videoCall,
                isMinimized: !state.agora.videoCall.isMinimized
            },
        }
    })),
    on(joinAgoraVideoCall, (state, {meetingId}) => ({
        ...state,
        agora: {
            chat: {
                ...state.agora.chat,
            },
            videoCall: {
                ...state.agora.videoCall,
                isActive: false,
                isJoining: true,
                meetingId: meetingId,
            },
        }
    })),
    on(joinAgoraVideoCallSuccess, (state) => ({
        ...state,
        agora: {
            chat: {
                ...state.agora.chat,
            },
            videoCall: {
                ...state.agora.videoCall,
                isActive: true,
                isJoining: false,
            },
        }
    })),
    on(joinAgoraVideoCallFailure, (state) => ({
        ...state,
        agora: {
            chat: {
                ...state.agora.chat,
            },
            videoCall: {
                ...state.agora.videoCall,
                isActive: false,
                isMinimized: false,
                isJoining: false,
            },
        }
    })),
    on(leaveAgoraVideoCall, (state) => ({
        ...state,
        agora: {
            chat: {
                ...state.agora.chat,
            },
            videoCall: {
                ...state.agora.videoCall,
                isActive: false,
                isMinimized: false,
                isJoining: false,
                videoCallTracks: null,
                meetingId: null,
            },
        }
    })),
);
