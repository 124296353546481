export enum TryTerraSupportedHealthProvider {
    // native only ios
    APPLE = 'APPLE',

    // native only android
    HEALTH_CONNECT = 'HEALTH_CONNECT',

    // native and web support
    FITBIT = 'FITBIT',
    GARMIN = 'GARMIN',
}

export const HealthProviderName: {
    [key in TryTerraSupportedHealthProvider]: string;
} = {
    [TryTerraSupportedHealthProvider.APPLE]: 'Salute',
    [TryTerraSupportedHealthProvider.HEALTH_CONNECT]: 'Connessione Salute',
    [TryTerraSupportedHealthProvider.FITBIT]: 'Fitbit',
    [TryTerraSupportedHealthProvider.GARMIN]: 'Garmin'
}

export interface TryTerraAuthWithProviderResponse {
    authUrl: string;
}

export interface TryTerraAuthTokenResponse {
    status: string;
    token: string;
    expires_in: number;
}

export interface TryTerraUserInfoResponse {
    status: string;
    users: TryTerraUser[];
}

export interface TryTerraUser {
    active: boolean;
    created_at: string;
    user_id: string;
    provider: string & TryTerraSupportedHealthProvider;
    last_webhook_update: string;
    reference_id: string;
    scopes: string;
}

export interface TryTerraTriggerWebhooksRequest {
    startDate: string;
    endDate: string;
    timeZone: string;
    provider: TryTerraSupportedHealthProvider;
}
