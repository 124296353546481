<div class="flex flex-col h-full">
    <div class="custom-bg-white relative px-6 h-full pb-28">
        <div class="flex items-center justify-between w-full mt-5">
            <h3 class="font-semibold text-lg">
                {{ healthDataLabel }}
            </h3>
        </div>

        @if (userTryTerraHasProviders$ | async) {
            <div class="flex items-center justify-between w-full mt-5">
                <p class="text-lg">
                    Non hai ancora connesso un health data provider ⌚! Vai <a class="text-primary cursor-pointer underline"
                                                                              [routerLink]="healthProvidersPath">qui</a>
                    e collegane uno per poter monitorare i tuoi progressi.
                </p>
            </div>
        }

        <nav class="mt-5">
            <ul class="flex items-center justify-between">
                <li
                    *ngIf="supportedPeriods.includes(HealthChartPeriod.day)"
                    [ngClass]="{
                        'bg-primary': period === HealthChartPeriod.day,
                        'bg-light-linear': period === HealthChartPeriod.day,
                        'text-white': period === HealthChartPeriod.day,
                        'text-black': period === HealthChartPeriod.day,
                    }"
                    class="{{
                        basisClass
                    }} py-2 px-5 w-fit rounded-full cursor-pointer"
                >
                    <a
                        class="flex items-center justify-center"
                        (click)="filterData(HealthChartPeriod.day)"
                    >
                        <div>
                            <span>Giorno</span>
                        </div>
                    </a>
                </li>
                <li
                    *ngIf="supportedPeriods.includes(HealthChartPeriod.week)"
                    [ngClass]="{
                        'bg-primary': period === HealthChartPeriod.week,
                        'bg-light-linear': period === HealthChartPeriod.week,
                        'text-white': period === HealthChartPeriod.week,
                        'text-black': period === HealthChartPeriod.week,

                    }"
                    class="{{
                        basisClass
                    }} py-2 px-5 w-fit rounded-full cursor-pointer"
                >
                    <a
                        class="flex items-center justify-center"
                        (click)="filterData(HealthChartPeriod.week)"
                    >
                        <div>
                            <span>Settimana</span>
                        </div>
                    </a>
                </li>
                <li *ngIf="supportedPeriods.includes(HealthChartPeriod.month)"
                    [ngClass]="{
                        'bg-primary': period === HealthChartPeriod.month,
                        'bg-light-linear': period === HealthChartPeriod.month,
                        'text-white': period === HealthChartPeriod.month,
                        'text-black': period === HealthChartPeriod.month,
                    }"
                    class="{{
                        basisClass
                    }} py-2 px-5 w-fit rounded-full cursor-pointer"
                >
                    <a
                        class="flex items-center justify-center"
                        (click)="filterData(HealthChartPeriod.month)"
                    >
                        <div>
                            <span>Mese</span>
                        </div>
                    </a>
                </li>
            </ul>
        </nav>
        <div class="flex items-center justify-between w-full mb-7 mt-5">
            <button
                class="border-2 border-primary rounded-full p-1"
                (click)="previousDate()"
            >
                <div class="flex items-center">
                    <div class="text-primary text-sm font-bold">
                        <svg class="w-4 h-4 fill-primary">
                            <use
                                href="../../../assets/svg/svGP.svg#chevron-left"
                            ></use>
                        </svg>
                    </div>
                </div>
            </button>
            <div>
                <span class="text-sm">{{ dateLabel }}</span>
            </div>
            <button
                class="border-2 border-primary rounded-full p-1"
                (click)="nextDate()"
            >
                <div class="flex items-center">
                    <div class="text-primary text-sm font-bold">
                        <svg class="w-4 h-4 fill-primary">
                            <use
                                href="../../../assets/svg/svGP.svg#chevron-right"
                            ></use>
                        </svg>
                    </div>
                </div>
            </button>
        </div>

        <!-- fine if -->
        <health-chart
            [healthDataValues]="(healthDataValues$ | async) ?? []"
            [chartPeriod]="period"
            [chartValueUnitSymbol]="chartValueUnitSymbol"
            [dateFrom]="dateFrom"
            [chartLabel]="chartLabel"
            [prefixAggregateLegendLabel]="
                aggregateOperation === HealthDataAggregateOperation.AVG
                    ? 'Media'
                    : ''
            "
            [chartConfiguration]="chartConfiguration"
            [isLoading]="(isLoading$ | async) ?? true"
        />

        @if (lastDataMonitored && dateLastMonitored && minValueMonitored && maxValueMonitored) {
            <div
                class="border-2 border-white bg-linear-gradient rounded-lg mt-5 card-shadow-bottom px-3 pt-3"
            >
                <div *ngIf="healthDataType != HealthDataType.SLEEP_IN_BED"
                     class="flex items-center justify-between mb-3">
                    <div class="bg-linear-gradient-reverse py-3 px-4 rounded-lg">
                        <h3 class="font-bold">Ultimo ({{ dateLastMonitored }})</h3>
                    </div>
                    <div>
                        <span><strong>{{ lastDataMonitored }}</strong>&nbsp;{{ healthDataUnitSymbolLabel }}</span>
                    </div>
                </div>
                <div *ngIf="healthDataType != HealthDataType.SLEEP_IN_BED"
                     class="flex items-center justify-between mb-3">
                    <div class="bg-linear-gradient-reverse py-3 px-4 rounded-lg">
                        <h3 class="font-bold">Intervallo</h3>
                    </div>
                    <div>
                            <span><strong>{{ minValueMonitored }}
                                - {{ maxValueMonitored }}</strong> {{ healthDataUnitSymbolLabel }}</span>
                    </div>
                </div>
                <div *ngIf="aggregateOperation === HealthDataAggregateOperation.SUM"
                     class="flex items-center justify-between mb-3">
                    <div class="bg-linear-gradient-reverse py-3 px-4 rounded-lg">
                        <h3 class="font-bold">Totale</h3>
                    </div>
                    <div>
                        <span><strong>{{ sumTotalValuesMonitored }}</strong>&nbsp;{{ healthDataUnitSymbolLabel }}</span>
                    </div>
                </div>
            </div>
        }
    </div>
</div>
