<section class="bg-body-bg h-screen flex flex-col justify-between">
    <div
        class="basis-10/12 flex items-center justify-center relative p-8 overflow-hidden">
        <img
            class="w-auto h-full z-10"
            [src]="tutorialImg"
            alt />
    </div>
    <div class="basis-2/12">
        <div class="bg-white w-full py-5 px-4 rounded-t-4xl">
            <h1 class="text-primary font-semibold text-xl">
                Se non hai già dato consenso alla condivisione dei dati della
                tua salute a questa App, nella prossima schermata,
                clicca su “Attiva tutte/Consenti tutte” e poi su “Consenti”.
            </h1>
            <p class="mt-3 text-text-color">
                Manterremo i tuoi dati privati e sicuri.
            </p>
            <div class="mt-7">
                <button
                    class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                    [routerLink]="importPath">
                    <span
                        class="text-white text-sm font-bold uppercase">Continua</span>
                </button>
            </div>
            <div class="mt-2">
                <button class="py-5 px-4 border-0 bg-danger rounded-lg flex items-center justify-center w-full"
                        (click)="goBack()">
                    <span class="text-white text-sm font-bold uppercase">Annulla</span>
                </button>
            </div>
        </div>
    </div>
</section>
