import {Routes} from '@angular/router';
import {
    AuthGuard,
    UserHasProductFeatureGuard,
    NoAuthGuard,
    UserHasPendingOrderGuard, IsMobileGuard,
    UserHasNotBasePlanGuard, RedirectToPendingOrderIfAnyGuard
} from './guards/auth.guard';
import {RegistrationGuard, SurveyGuard} from './guards/registration.guard';
import {UserHasTodayFeelingSurveyGuard} from './guards/feeling.guard';
import {ProductFeature} from './models/product.model';
import {APP_ROUTES} from './app.routes.definition';
import {ExpiredInitialSurveyGuard} from './guards/expired-initial-survey.guard';
import {LifeWheelSurveyGuard} from './guards/life-wheel-survey.guard';
import MainContainerComponent from "./components/main-container/main-container.component";
import HomePageComponent from "./pages/home-page/home-page.component";
import ExpiredInitialSurveyPageComponent
    from "./pages/expired-initial-survey-page/expired-initial-survey-page.component";
import LifeWheelSurveyPageComponent from "./pages/life-wheel-survey-page/life-wheel-survey-page.component";
import AppointmentsPageComponent from "./pages/appointments-page/appointments-page.component";
import {MONITOR_ROUTES} from "./pages/monitor-page/monitor.route";
import MessagingPageComponent from "./pages/messaging-page/messaging-page.component";
import ConversationComponent from "./pages/messaging-page/conversation/conversation.component";
import FreeConversationComponent from "./pages/messaging-page/free-conversation/free-conversation.component";
import {MARKETPLACES_ROUTES} from "./pages/marketplace-page/marketplaces.routes";
import PaymentRedirectPageComponent from "./pages/payment-redirect-page/payment-redirect-page.component";
import FeelingPageComponent from "./pages/feeling-page/feeling-page.component";
import {SETTINGS_ROUTES} from "./pages/settings-page/settings.route";
import {BUY_BASE_PLAN_ROUTES} from "./pages/buy-base-plan/buy-base-plan.routes";
import PendingOrderPageComponent from "./pages/pending-order-page/pending-order-page.component";
import {ASK_IMPORT_HEALTH_DATA_ROUTES} from "./pages/ask-import-health-data-page/ask-import-health-data.routes";
import RatingPageComponent from "./pages/rating-page/rating-page.component";
import ResetPasswordPageComponent from "./pages/reset-password-page/reset-password-page.component";
import AuthPageComponent from "./pages/auth-page/auth-page.component";
import {LOGIN_PAGE_ROUTES} from "./pages/login-page/login-page.route";
import RegistrationPageComponent from "./pages/registration-page/registration-page.component";
import SurveyPageComponent from "./pages/survey-page/survey-page.component";
import ActivateAccountPageComponent from "./pages/activate-account-page/activate-account-page.component";
import SurveyInvitationPageComponent from "./pages/survey-invitation-page/survey-invitation-page.component";

export const routes: Routes = [
    {path: '', redirectTo: 'home', pathMatch: 'full'},
    {
        path: '',
        data: {},
        component: MainContainerComponent,
        children: [
            {
                path: APP_ROUTES.HOME(true),
                data: {
                    title: 'Home',
                    showInNavbar: true,
                    showAvatar: true,
                    showTopBar: true,
                    showBottomBar: true,
                },
                component: HomePageComponent,
                canActivate: [AuthGuard],
            },
            {
                path: APP_ROUTES.EXPIRED_INITIAL_SURVEY(true),
                data: {
                    title: 'Expired Initial Survey',
                    showInNavbar: false,
                    showAvatar: false,
                    showTopBar: true,
                    showBottomBar: false,
                },
                component: ExpiredInitialSurveyPageComponent,
                canActivate: [AuthGuard, ExpiredInitialSurveyGuard],
            },
            {
                path: APP_ROUTES.LIFE_WHEEL_SURVEY(true),
                data: {
                    title: 'Life Wheel Survey',
                    showInNavbar: false,
                    showAvatar: false,
                    showTopBar: false,
                    showBottomBar: false,
                },
                component: LifeWheelSurveyPageComponent,
                canActivate: [AuthGuard, LifeWheelSurveyGuard],
            },
            {
                path: APP_ROUTES.APPOINTMENTS(true),
                data: {
                    title: 'Appointments',
                    showInNavbar: true,
                    showAvatar: true,
                    showTopBar: true,
                    showBottomBar: true,
                },
                component: AppointmentsPageComponent,
                canActivate: [AuthGuard],
            },
            {
                path: APP_ROUTES.MONITOR(true),
                data: {
                    title: 'Monitor',
                    showInNavbar: true,
                    showAvatar: true,
                    showTopBar: true,
                    showBottomBar: true,
                },
                children: MONITOR_ROUTES,
                canActivate: [AuthGuard],
            },
            {
                path: APP_ROUTES.MESSAGING(true),
                data: {
                    title: 'Messaging',
                    showInNavbar: true,
                    showAvatar: true,
                    showTopBar: true,
                    showBottomBar: true,
                },
                component: MessagingPageComponent,
                canActivate: [AuthGuard],
            },
            {
                path: APP_ROUTES.MESSAGING_ID(true, ':id'),
                data: {
                    title: 'Message Detail',
                    showInNavbar: false,
                    showAvatar: false,
                    // showTopBar: true,
                    showBottomBar: false,
                },
                component: ConversationComponent,
                canActivate: [
                    AuthGuard,
                    UserHasProductFeatureGuard(ProductFeature.CHAT),
                ],
            },
            {
                path: APP_ROUTES.FREE_MESSAGING(true, ':id'),
                data: {
                    title: 'Free Messaging',
                    showInNavbar: false,
                    showAvatar: false,
                    showBottomBar: false,
                    // showTopBar: true,
                },
                component: FreeConversationComponent,
                canActivate: [AuthGuard],
            },
            {
                path: APP_ROUTES.MARKETPLACE(true),
                data: {
                    title: 'Marketplace',
                    showInNavbar: true,
                    showAvatar: true,
                    showTopBar: true,
                    showBottomBar: true,
                },
                children: MARKETPLACES_ROUTES,
                canActivate: [AuthGuard],
            },
            {
                path: APP_ROUTES.PAYMENT_REDIRECT(true),
                data: {
                    title: 'Payment Redirect',
                    showInNavbar: false,
                    showAvatar: false,
                    showTopBar: true,
                    showBottomBar: true,
                },
                component: PaymentRedirectPageComponent,
                canActivate: [AuthGuard],
            },
            {
                path: APP_ROUTES.FEELING(true),
                data: {
                    title: 'Feeling',
                    showInNavbar: false,
                    showAvatar: false,
                    showTopBar: true,
                    showBottomBar: true,
                },
                component: FeelingPageComponent,
                canActivate: [AuthGuard, UserHasTodayFeelingSurveyGuard],
            },
            {
                path: APP_ROUTES.SETTINGS(true),
                data: {
                    title: 'Settings',
                    showInNavbar: false,
                    showAvatar: false,
                    showTopBar: true,
                    showBottomBar: true,
                },
                children: SETTINGS_ROUTES,
                canActivate: [AuthGuard],
            },
            {
                path: APP_ROUTES.BUY_BASE_PLAN(true),
                data: {
                    title: 'Buy Base Plan',
                    showInNavbar: false,
                    showAvatar: false,
                    showTopBar: true,
                    showBottomBar: false,
                    showSettingsLink: false,
                },
                children: BUY_BASE_PLAN_ROUTES,
                canActivate: [AuthGuard, UserHasNotBasePlanGuard, RedirectToPendingOrderIfAnyGuard],
            },
            {
                path: APP_ROUTES.PENDING_ORDER(true, ':id'),
                data: {
                    title: 'Pending Order',
                    showInNavbar: false,
                    showAvatar: false,
                    showTopBar: true,
                    showBottomBar: true,
                },
                component: PendingOrderPageComponent,
                canActivate: [AuthGuard, UserHasPendingOrderGuard],
            },
            {
                path: APP_ROUTES.ASK_IMPORT_HEALTH_DATA(true),
                data: {
                    title: 'Ask Import Health Data',
                    showInNavbar: false,
                    showAvatar: false,
                    showBottomBar: false,
                    showTopBar: false,
                },
                children: ASK_IMPORT_HEALTH_DATA_ROUTES,
                canActivate: [
                    AuthGuard, IsMobileGuard
                ],
            },
            {
                path: APP_ROUTES.RATING(true),
                data: {
                    title: 'Rating',
                    showInNavbar: false,
                    showAvatar: false,
                    showBottomBar: false,
                    showTopBar: false,
                },
                component: RatingPageComponent,
            },
        ],
    },
    {
        path: APP_ROUTES.RESET_PASSWORD(true),
        component: ResetPasswordPageComponent,
    },
    {
        path: APP_ROUTES.AUTH(true),
        component: AuthPageComponent,
        canActivate: [NoAuthGuard],
    },
    {
        path: APP_ROUTES.LOGIN(true),
        children: LOGIN_PAGE_ROUTES,
        canActivate: [NoAuthGuard],
    },
    {
        path: APP_ROUTES.REGISTER(true),
        component: RegistrationPageComponent,
        canActivate: [RegistrationGuard, NoAuthGuard],
    },
    {
        path: APP_ROUTES.SURVEY(true),
        component: SurveyPageComponent,
        canActivate: [SurveyGuard, NoAuthGuard],
    },
    {
        path: APP_ROUTES.ACTIVATE(true),
        component: ActivateAccountPageComponent,
        canActivate: [],
    },
    {
        path: APP_ROUTES.SURVEY_INVITATION(true, ':id'),
        component: SurveyInvitationPageComponent,
        canActivate: [],
    },
    {
        path: '**',
        redirectTo: APP_ROUTES.HOME(true),
    },
];
