<div class="flex flex-col items-center justify-start w-fit mt-5 bg-white py-7 px-6 mx-auto rounded-3xl">
    <img class="size-20 p-1 border border-light-grey rounded"
         src="assets/img/providers/{{provider.toLowerCase()}}.png"
         [alt]="HealthProviderName[provider]">
    <h1 class="font-semibold text-lg mt-7">
        Stai
        per {{ isConnecting ? 'connettere' : 'disconnettere' }}&nbsp;<span>{{ HealthProviderName[provider] }}</span>
    </h1>
    <div class="flex items-center justify-around w-full mt-7">
        <button class="flex items-center py-4 px-5 bg-danger disabled:bg-black-60 rounded-lg cursor-pointer"
                (click)="onClose()">
            <div>
                <span class="text-xs text-white appointment-join__text font-bold">
                    Annulla
                </span>
            </div>
        </button>
        <button class="flex items-center py-4 px-5 bg-primary disabled:bg-black-60 rounded-lg cursor-pointer"
                [disabled]="isRequestingAuth || isRequestingDeauth ||
                    ([HealthProviderName.HEALTH_CONNECT, HealthProviderName.APPLE].includes(provider) && (tryTerraIsNativeInitializing$ | async))"
                (click)="isConnecting ? authWithProvider() : deauthProvider()">
            <div>
                @if (isRequestingAuth || isRequestingDeauth ||
                    ([HealthProviderName.HEALTH_CONNECT, HealthProviderName.APPLE].includes(provider) && (tryTerraIsNativeInitializing$ | async))) {
                    <span class="button-loader"></span>
                } @else {
                    <span class="text-xs text-white appointment-join__text font-bold">
                    {{ isConnecting ? 'Connetti' : 'Disconnetti' }}
                </span>
                }
            </div>
        </button>
    </div>
</div>
